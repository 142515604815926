@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/texts";
@use "src/styles/tokens/generatedColors";

$errorIconColorVarName: --intelas-error-page-base-icon-color;

.errorPageBase {
    display: flex;
    position: relative;
    align-items: center;
    height: 100%;
    padding: 0 96px;
}

.rounded {
    border-radius: 16px;
}

.type {
    &_external {
        background-color: #070518;
        #{$errorIconColorVarName}: generatedColors.$colorsSecondaryLightPurple200;
    }

    &_internal {
        background-color: generatedColors.$colorsSecondaryPurple25;
        #{$errorIconColorVarName}: colors.$primary_darkPurple;
    }
}

.noBackground {
    background-color: transparent;
}

.content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    column-gap: 24px;
}

.number {
    display: flex;
    color: transparent;

    // Inter looks really bad in outluned variant
    font-family: Roboto, sans-serif;
    font-size: 200px;
    font-weight: 900;
    -webkit-text-stroke: 2px generatedColors.$colorsPrimaryPurple;
}

.info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 450px;
    row-gap: 16px;
}

.icon {
    width: 200px;
    height: 200px;
    color: var($errorIconColorVarName);
}

.externalGradient_1 {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 70%;
}

.externalGradient_2 {
    position: absolute;
    top: 0;
    right: 0;
    height: 70%;
}
