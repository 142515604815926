@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/animation";
@use "src/styles/tokens/generatedColors";
@use "src/styles/utils";

.checkbox {
    display: inline-flex;
    position: relative;
    box-sizing: border-box;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-style: solid;
    border-radius: radius.$m;
    border-color: generatedColors.$colorsSecondaryGrey400;
    background-color: generatedColors.$colorsPrimaryWhite;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
        &:not(.disabled) {
            border-color: generatedColors.$colorsPrimaryPurple;
        }
    }

    &:focus-within {
        &:not(.disabled) {
            @include shadows.boxShadowOuter(generatedColors.$colorsSecondaryLightPurple200);
        }
    }
}

.size {
    &_s {
        width: 16px;
        height: 16px;
    }

    &_m {
        width: 20px;
        height: 20px;
    }
}

.checkBoxInput {
    @extend .visuallyHidden;
}

.checked,
.indeterminate {
    &:not(.disabled) {
        border-color: generatedColors.$colorsPrimaryPurple;
        background-color: generatedColors.$colorsPrimaryPurple;
    }
}

.disabled {
    border-color: generatedColors.$colorsSecondaryGrey400;
    opacity: 0.4;
    cursor: default;

    &.checked,
    &.indeterminate {
        background-color: generatedColors.$colorsSecondaryGrey400;
    }
}
