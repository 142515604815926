@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/tokens/generatedColors";

.companyInvitesSubpage {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
}

.filters {
    flex-grow: 1;
}

.headerBox {
    display: flex;
    padding-bottom: 24px;
}
