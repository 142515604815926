@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/animation";
@use "src/styles/tokens/generatedColors";
@import "src/styles/alertTypes";

.uploadedFileBase {
    display: flex;
    box-sizing: border-box;
    flex-grow: 1;
    height: 50px;
    border-width: 1px;
    border-style: solid;
    border-radius: radius.$m;
}

.button {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 100%;
    border-left: solid 1px generatedColors.$colorsSecondaryGrey100;
    color: generatedColors.$colorsSecondaryGrey600;
    cursor: pointer;

    &:active {
        color: inherit;
    }
}
