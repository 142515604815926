@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";
@use "src/styles/radius";
@use "src/styles/shadows";

.selectItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 4px 14px 16px;
    cursor: pointer;

    &:hover {
        background-color: generatedColors.$colorsSecondaryPurple25;
    }
}

.icon {
    margin-left: 4px;
}
