@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/animation";
@use "src/styles/tokens/generatedColors";

.lazyTableContainer {
    position: relative;
    width: 100%;
}

.placeholder {
    width: 100%;
    border: solid 1px generatedColors.$colorsSecondaryGrey100;
    border-radius: radius.$l;
    background-color: generatedColors.$colorsPrimaryWhite;
    box-shadow: shadows.$elevation03;
}
