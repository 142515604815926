@use "@/styles/colors";
@use "src/styles/tokens/generatedColors";

.head {
    margin-top: 12px;
    padding: 0 0 12px;
    border-top: 1px solid generatedColors.$colorsSecondaryGrey100;

    .headRow {
        display: flex;
        flex-direction: row;
        margin-top: 12px;

        .headRowCell {
            display: flex;
            flex-direction: row;
            width: 50%;

            .headRowCellTitle {
                width: 40%;
            }

            .headRowCellValue {
                width: calc(60% - 25px);
                padding-right: 12px;
                padding-left: 12px;

                //border-left: 1px solid generatedColors.$colorsSecondaryGrey100;
            }
        }
    }
}

.problemsCount {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
    padding: 4px 8px;
    border-radius: 2px;
    background: generatedColors.$colorsTetrialRed50;

    .text {
        position: relative;
        top: 1px;
        margin-left: 4px;
        color: generatedColors.$colorsTetrialRed500;
        font-weight: 600;
    }
}

.okMessage {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
    padding: 4px 8px;
    border-radius: 2px;
    background: colors.$background_green;

    .text {
        position: relative;
        top: 1px;
        margin-left: 4px;
        color: colors.$status_green;
        font-weight: 600;
    }
}

.errorTextCell {
    padding: 6px 0;
    word-wrap: normal;
    word-break: normal;
}

.goToDetailsLink {
    display: block;
    margin-top: 24px;
    font-size: 14px;
}

.dashCell {
    padding: 12px 10px;
}
