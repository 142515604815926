// Reusable texts config
// Connect it to your file through '@import'
// Use text variants with '@extend'

$fontFamily: Inter, Roboto, sans-serif; /* stylelint-disable-line value-keyword-case */

.text_variant {
    &_labelMediumDefault {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
    }

    &_labelSemiboldDefault {
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
    }

    &_bodyMedium {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
    }

    &_smallTextMediumDefault {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }

    &_smallTextSemiboldDefault {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
    }

    &_extraSmallRegularDefault {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
    }

    &_bodySemiboldDefault {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
    }

    &_subheader {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
    }

    &_header {
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
    }

    &_hint {
        font-size: 10px;
        font-weight: 600;
        line-height: 16px;
    }

    &_cell_smallTextMediumDefault {
        font-size: 14px;
        font-weight: 500;
    }

    &_cell_smallTextSemiboldDefault {
        font-size: 14px;
        font-weight: 700;
    }
}
