@use "src/styles/tokens/generatedColors";
@use "src/styles/radius";
@use "src/styles/animation";
@use "src/styles/variableNames";
@use "src/styles/texts";

$smallFont: 12px;

.table :global {
    // font
    --ag-font-size: 13px;
    --ag-font-family: #{texts.$fontFamily};
    // sizes
    --ag-grid-size: 3px; // main size
    --ag-cell-horizontal-padding: 6px;
    --ag-row-height: 24px;
    --ag-header-height: 28px;
    --ag-header-column-resize-handle-width: 1px;
    // borders
    --ag-borders: solid 1px;
    --ag-borders-secondary: none;
    --ag-row-border-style: none;
    // colors
    --neo-bg-grey: #{generatedColors.$colorsSecondaryGrey50}88; // add aplha to make it lighter
    --neo-bg-grey-500: #{generatedColors.$colorsSecondaryGrey500};
    --neo-bg-purple-900: #{generatedColors.$colorsSecondaryPurple900};
    --ag-row-border-color: transparent;
    --ag-border-color: #{generatedColors.$colorsSecondaryGrey600};
    --ag-odd-row-background-color: transparent;
    --ag-header-background-color: var(--neo-bg-grey);
    --ag-header-foreground-color: #{generatedColors.$colorsSecondaryGrey800};
    --ag-data-color: #{generatedColors.$colorsSecondaryGrey800};
    --ag-header-column-resize-handle-color: #{generatedColors.$colorsSecondaryGrey100};
    --ag-row-hover-color: #{generatedColors.$colorsSecondaryLightPurple100};
    --ag-alpine-active-color: #{generatedColors.$colorsTetrialBlue500};
    --ag-active-color: #{generatedColors.$colorsTetrialBlue500};
    // icons
    --ag-icon-font-family: "agGridMaterial";
    --ag-icon-size: 14px;
}

.table {
    // headers
    :global(.ag-header-row) {
        // @extend .text_variant_bodySemiboldDefault;
        font-size: 15px;
        font-weight: 700;
    }
    // subheader borders
    :global(.ag-header-row:not(:first-child) .ag-header-cell:not(.ag-header-span-height.ag-header-span-total)) {
        border-top: none;
    }

    // border classes
    :global(.neo-right-border-1) {
        border-right: var(--ag-borders) var(--ag-border-color);
    }
    // background highlight classes
    :global(.ag-row:not(.ag-row-hover) .neo-bg-grey) {
        background-color: var(--neo-bg-grey);
    }

    :global(.neo-bg-grey-500) {
        --ag-header-column-resize-handle-color: var(--neo-bg-grey-500);

        background-color: var(--neo-bg-grey-500) !important;
        color: generatedColors.$colorsPrimaryWhite;
    }

    :global(.neo-bg-purple-900) {
        --ag-header-column-resize-handle-color: var(--neo-bg-purple-900);

        background-color: var(--neo-bg-purple-900) !important;
        color: generatedColors.$colorsPrimaryWhite;
    }

    // group, footer, grand total rows / bold only for 0 level groups
    :global(.ag-row-group.ag-row-level-0),
    :global(.ag-row-footer.ag-row-level-0),
    :global(.ag-row-footer.ag-row-level--1),
    :global(.neo-grand-total-row) {
        font-weight: 700;
    }

    // group total highlight classes
    :global(.ag-row-footer:not(.ag-row-level--1) .neo-highlight-total) {
        background-color: generatedColors.$colorsSecondaryPurple50;
    }

    :global(.ag-row-footer:not(.ag-row-level--1) .neo-highlight-total:not(:focus-within)) {
        border-top-color: var(--ag-border-color);
    }

    // grand total highlight
    :global(.ag-row-footer.ag-row-level--1 .ag-cell) {
        // fix padding because of 0 left/right borders
        padding-right: var(--ag-cell-horizontal-padding);
        padding-left: calc(
            var(--ag-cell-horizontal-padding) + var(--ag-row-group-indent-size) * var(--ag-indentation-level)
        );
        border-width: 4px 0;
        border-top-color: generatedColors.$colorsPrimaryWhite;
        border-bottom-color: generatedColors.$colorsPrimaryWhite;
    }

    :global(.ag-row-footer.ag-row-level--1 .neo-highlight-total),
    :global(.ag-row.neo-grand-total-row .neo-highlight-total) {
        background-color: generatedColors.$colorsSecondaryPurple900;
        color: generatedColors.$colorsPrimaryWhite;
    }

    // row classes
    :global(.neo-italic-row) {
        font-style: italic;
    }

    :global(.neo-small-font-row) {
        font-size: $smallFont;
    }

    :global(.neo-separator-row) {
        color: transparent;
    }

    // to overide subheader border in some cases
    :global(.neo-no-top-border) {
        border-top: none !important;
    }

    // cells
    :global(.neo-clickable) {
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    // active filter icon with blue dot
    :global(.ag-filter-active) {
        position: relative;
    }

    :global(.ag-icon-filter) {
        z-index: 1;
    }

    :global(.ag-filter-active::before) {
        content: "";
        position: absolute;
        z-index: 2;
        top: -2px;
        right: -2px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: generatedColors.$colorsPrimaryWhite;
    }

    :global(.ag-filter-active::after) {
        content: "";
        position: absolute;
        z-index: 3;
        top: -1px;
        right: -1px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: var(--ag-active-color);
    }
}
