@use "src/styles/tokens/generatedColors";
@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/shadows";

.link {
    text-decoration: none;
    cursor: pointer;
    user-select: none;

    &:hover {
        text-decoration: underline;
    }
}
