/**
 * Use this overrides for customizing Velt components. Other than dialog items.
 * For dialog items, use `VELT_DIALOG_STYLES` constant.
 */

@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/animation";
@use "src/styles/tokens/generatedColors";

#root > .app {
    & velt-sidebar-button {
        // Radius
        --velt-tool-border-radius: #{radius.$m};
        --velt-tool-active-border-radius: #{radius.$m};
        --velt-tool-focus-border-radius: #{radius.$m};
        --velt-tool-hover-border-radius: #{radius.$m};
        // Icon color
        --velt-tool-icon-color: #{generatedColors.$colorsPrimaryPurple};
        --velt-tool-active-icon-color: #{generatedColors.$colorsPrimaryPurple};
        --velt-tool-focus-icon-color: #{generatedColors.$colorsPrimaryPurple};
        --velt-tool-hover-icon-color: #{generatedColors.$colorsPrimaryPurple};
        // Background
        --velt-tool-active-bg-color: #{generatedColors.$colorsSecondaryLightPurple100};
        --velt-tool-focus-bg-color: #{generatedColors.$colorsSecondaryLightPurple100};
        --velt-tool-hover-bg-color: #{generatedColors.$colorsSecondaryLightPurple50};
        // Border
        --velt-tool-border: 1px solid #{generatedColors.$colorsPrimaryPurple};
        --velt-tool-active-border: 1px solid #{generatedColors.$colorsPrimaryPurple};
        --velt-tool-focus-border: 1px solid #{generatedColors.$colorsPrimaryPurple};
        --velt-tool-hover-border: 1px solid #{generatedColors.$colorsPrimaryPurple};
    }
}

snippyly-comments-sidebar,
.snippyly-sidebar-panel,
velt-comments-sidebar {
    --velt-comments-sidebar-border-color: #{generatedColors.$colorsSecondaryGrey100} !important;
    --velt-comments-sidebar-close-icon-color: #{generatedColors.$colorsPrimaryPurple} !important;
    --velt-comments-sidebar-primary-color: #{generatedColors.$colorsPrimaryPurple} !important;
    --velt-comments-sidebar-secondary-color: #{generatedColors.$colorsSecondaryGrey600} !important;
}

/* stylelint-disable selector-class-pattern */

// WARN: Dangerous selector, can be broken on Velt update
div[data-velt-element="true"][data-velt-comment-disabled="true"].s-sidebar-container .s-sidebar {
    border-radius: radius.$m !important;
}

// WARN: Dangerous selector, can be broken on Velt update
.s-sidebar {
    border-radius: radius.$m !important;
}

snippyly-user-avatar {
    display: none;
}

div.modal-div.no-comments .reply-div {
    border-radius: 4px !important;
}

div.reply-div input-text-wrap ng-star-inserted {
    border: solid 1px generatedColors.$colorsSecondaryGrey100 !important;
}

.modal-div.no-comments .reply-div {
    border: solid 1px #{generatedColors.$colorsSecondaryGrey100} !important;
}

/* stylelint-enable selector-class-pattern */
