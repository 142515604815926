@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/spaces";

.createForm {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}

.delimeter {
    position: relative;
    left: - spaces.$formBox_horizontalPadding;
    width: calc(100% + #{spaces.$formBox_horizontalPadding} * 2);
    margin: 24px 0;
    border-bottom: solid 1px generatedColors.$colorsSecondaryGrey100;
}

.comments {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}
