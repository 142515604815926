@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/tokens/generatedColors";

.moreIcon {
    display: flex;
    flex-shrink: 0;
}

.actionsCell {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
