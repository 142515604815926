@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/animation";
@use "src/styles/tokens/generatedColors";
@use "src/styles/sizes";
@use "src/styles/texts";

.contentWithMenu {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: 100%;
    padding-left: sizes.$sideMenu_small_width;
    font-family: texts.$fontFamily;
}

.menu {
    display: flex;
    z-index: 2;
    flex-direction: column;
    flex-shrink: 0;
    height: 100%;
    border-right: 1px solid generatedColors.$colorsSecondaryGrey100;
    background: generatedColors.$colorsSecondaryPurple25;
}

.content {
    display: flex;
    position: relative;
    z-index: 1;
    flex-direction: column;
    flex-grow: 1;
    background-color: generatedColors.$colorsPrimaryWhite;
}

.contentContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-grow: 1;
    min-width: 800px;
    max-height: 100%;
}
