// Feb25 Flat Tables: some overrrides for flat tables
// https://www.figma.com/design/7MzBACeoQyd2oKATaqCOxa/Table-Aesthetic-Redesign?node-id=7597-27441&t=u7ByjxvRz9y59lVQ-4

@use "src/styles/tokens/generatedColors";

.feb25FlatTable :global {
    // Tool Panel
    --ag-control-panel-background-color: #f7f7fc;
    --ag-grid-size: 5px;
    // sizes
    --ag-cell-horizontal-padding: 12px;
    --ag-row-height: 32px;
    // header
    --ag-header-height: 32px;
    --ag-header-background-color: #e9effb;
    --ag-header-cell-hover-background-color: #e9effb;
    --ag-header-foreground-color: #{generatedColors.$colorsSecondaryGrey800};

    // Filter list in Tool Panel
    .ag-set-filter-list {
        height: fit-content;
    }

    // Custom filter panel in Tool Panel
    .ag-react-container {
        flex-grow: 1;
        max-width: 100%;
    }
}
