@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/tokens/generatedColors";

.drugAndDropArea {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100px;
    padding: 16px;
    border: 1px dashed colors.$stroke_blue;
    border-radius: radius.$m;
    text-align: center;
    cursor: pointer;
    row-gap: 16px;
}

.disabled {
    border-color: generatedColors.$colorsSecondaryGrey100;
    cursor: default;
}

.fileOver:not(.disabled) {
    background-color: generatedColors.$colorsSecondaryPurple25;
}

.loading {
    border-style: solid;
    background-color: generatedColors.$colorsSecondaryPurple25;
    cursor: default;
}

.input {
    display: none;
}
