@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";

.panel {
    display: grid;
    box-sizing: border-box;
    grid-template-columns: 100%;
    grid-template-rows: min-content auto;
    flex-grow: 1;
    max-width: 100%;
    height: 100%;
    padding:
        var(--ag-widget-horizontal-spacing, 12px)
        var(--ag-widget-horizontal-spacing, 12px)
        0
        var(--ag-grid-size, 6px);
    overflow: hidden;
}

.search {
    position: relative;
    padding-bottom: var(--ag-widget-horizontal-spacing, 12px);

    &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -1px;
        left: calc(var(--ag-widget-horizontal-spacing, 12px) * -1);
        width: calc(100% + var(--ag-widget-horizontal-spacing, 12px) * 2);
        border-bottom: 1px solid var(--ag-input-border-color, generatedColors.$colorsSecondaryGrey400);
    }
}

.filter {
    max-height: 100%;
    margin-right: calc(var(--ag-widget-horizontal-spacing, 12px) * -1);
    overflow: auto;
}

.tree {
    margin: var(--ag-widget-horizontal-spacing, 12px) 0;
}
