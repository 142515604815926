@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";

.agGridTableLoading {
    position: relative;
    width: 100%;
    height: 100%;
}

.background {
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background: generatedColors.$colorsSecondaryPurple25;
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
