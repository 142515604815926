@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";
@use "src/styles/radius";
@use "src/styles/shadows";

.popover {
    margin-top: 4px;
    border: solid 1px generatedColors.$colorsSecondaryGrey100;
    box-shadow: shadows.$dropdownElevation;
}
