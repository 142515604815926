@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/animation";
@use "src/styles/tokens/generatedColors";

.alert {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    padding: 16px;
    border: 1px solid generatedColors.$colorsTetrialOrange300;
    border-radius: radius.$m;
    background: generatedColors.$colorsTetrialRed50;

    a {
        color: generatedColors.$colorsSecondaryPurple600;
    }
}

.message {
    margin-bottom: 8px;

    div {
        margin-bottom: 4px;
    }
}

.accounts {
    margin-bottom: 4px;
}
