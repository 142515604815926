@use "src/styles/colors";
@use "src/styles/shadows";
@use "src/styles/animation";
@use "src/styles/tokens/generatedColors";
@import "src/styles/utils";

$borderColor: --intelas-switch-border-color;
$bodyColor: --intelas-switch-body-color;
$thumbColor: --intelas-switch-thumb-color;

.switch {
    #{$borderColor}: generatedColors.$colorsSecondaryGrey400;
    #{$bodyColor}: generatedColors.$colorsPrimaryWhite;
    #{$thumbColor}: generatedColors.$colorsSecondaryGrey400;
    display: inline-flex;
    gap: 12px;
    align-items: center;
    cursor: pointer;
    user-select: none;

    &:not(.disabled):hover,
    &:focus-within {
        #{$borderColor}: generatedColors.$buttonsPrimaryPBgPrimary;
        #{$thumbColor}: generatedColors.$buttonsPrimaryPBgPrimary;
    }

    &.checked {
        #{$borderColor}: generatedColors.$buttonsPrimaryPBgPrimary;
        #{$bodyColor}: generatedColors.$buttonsPrimaryPBgPrimary;
        #{$thumbColor}: generatedColors.$buttonsPrimaryPIconPrimary;

        &:not(.disabled):hover,
        &:focus-within {
            #{$borderColor}: generatedColors.$buttonsPrimaryPBgHover;
            #{$bodyColor}: generatedColors.$buttonsPrimaryPBgHover;
            #{$thumbColor}: generatedColors.$buttonsPrimaryPIconPrimary;
        }
    }

    &:focus-within {
        .track {
            @include shadows.boxShadowOuter(generatedColors.$buttonsPrimaryPBorderFocused);
        }
    }

    &.disabled {
        opacity: 0.3;
        cursor: auto;
    }

    &.labelPlacementStart {
        flex-direction: row-reverse;
    }
}

.input {
    @extend .visuallyHidden;
}

.track {
    display: block;
    position: relative;
    box-sizing: border-box;
    flex: 0 0 auto;
    width: 36px;
    height: 20px;
    border: 1px solid var(--intelas-switch-border-color);
    border-radius: 10px;
    background-color: var(--intelas-switch-body-color);
}

.thumb {
    display: flex;
    position: absolute;
    top: 2px;
    left: 2px;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    transition: transform animation.$speed_fast animation.$pattern_default;
    border-radius: 50%;
    background-color: var(--intelas-switch-thumb-color);
    color: var(--intelas-switch-body-color);

    &_checked {
        transform: translateX(16px);
    }
}
