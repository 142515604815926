@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/tokens/generatedColors";
@import "src/styles/texts";

.buttonGroup {
    display: flex;
    box-sizing: border-box;
    overflow: hidden;
    border-width: 1px;
    border-style: solid;
    border-radius: radius.$m;
    border-color: generatedColors.$colorsSecondaryGrey100;
}

.button {
    max-width: 200px;
    padding: 5px 16px;
    overflow: hidden;
    border: 0;
    outline: none;
    background-color: transparent;
    font-family: $fontFamily;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;

    &:hover:not(.active) {
        background-color: generatedColors.$colorsSecondaryPurple25;
    }
}

.active {
    background-color: generatedColors.$colorsSecondaryLightPurple200;
    color: generatedColors.$colorsPrimaryPurple;
    cursor: default;
}
