@use "src/styles/colors";
@use "src/styles/shadows";
@use "src/styles/zIndex";
@use "src/styles/tokens/generatedColors";

.sideModalContainer {
    display: flex;
    position: absolute;
    right: 0;
    width: fit-content;
    max-width: calc(100% - 64px); // 64px for right menu
    height: 100%;
}

.sideModalBG {
    position: fixed;
    z-index: zIndex.$sideModal;
    inset: 0;
}
