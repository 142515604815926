@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/animation";
@use "src/styles/tokens/generatedColors";
@use "src/styles/zIndex";
@import "src/styles/texts";

.overlay {
    display: flex;
    position: fixed;
    z-index: zIndex.$baseModal;
    inset: 0;
    align-items: center;
    justify-content: center;
    padding: 36px;
    background-color: generatedColors.$colorsTransparentOverlay;
}

.baseModal {
    box-sizing: border-box;
    flex-grow: 1;
    flex-shrink: 0;
    background-color: generatedColors.$colorsPrimaryWhite;
    box-shadow: shadows.$dropdownElevation;
    font-family: $fontFamily;
}
