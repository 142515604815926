$buttonsAlertsBgDisable: #fff6;
$buttonsAlertsBgFocused: #eff8ff;
$buttonsAlertsBgHover: #eff8ff;
$buttonsAlertsBgPrimary: #fff;
$buttonsAlertsBorderDisable: #84caff;
$buttonsAlertsBorderFocused: #2166b2;
$buttonsAlertsBorderFocused2Px: #d1e9ff;
$buttonsAlertsBorderHover: #2166b2;
$buttonsAlertsBorderLoading: #2166b2;
$buttonsAlertsBorderPressed: #194f8a;
$buttonsAlertsBorderPrimary: #2e90fa;
$buttonsAlertsIconDisable: #84caff;
$buttonsAlertsIconFocused: #2166b2;
$buttonsAlertsIconHover: #2166b2;
$buttonsAlertsIconPressed: #194f8a;
$buttonsAlertsIconPrimary: #2e90fa;
$buttonsAlertsTextDisable: #84caff;
$buttonsAlertsTextFocused: #2166b2;
$buttonsAlertsTextHover: #2166b2;
$buttonsAlertsTextLoading: #2166b2;
$buttonsAlertsTextPressed: #194f8a;
$buttonsAlertsTextPrimary: #2e90fa;
$buttonsDestructiveBgDisable: #f9b3b6;
$buttonsDestructiveBgFocused: #eb0a15;
$buttonsDestructiveBgHover: #eb0a15;
$buttonsDestructiveBgLoading: #f9b3b6;
$buttonsDestructiveBgPressed: #d60913;
$buttonsDestructiveBgPrimary: #ef3b44;
$buttonsDestructiveBorderFocused: #f9b3b6;
$buttonsDestructiveIconPrimary: #fff;
$buttonsDestructiveTextLoader: #d60913;
$buttonsDestructiveTextPrimary: #fff;
$buttonsFlatPIconDisable: #dbdbff;
$buttonsFlatPIconFocused: #4141a3;
$buttonsFlatPIconHover: #4141a3;
$buttonsFlatPIconPressed: #33337e;
$buttonsFlatPIconPrimary: #5c5ce5;
$buttonsFlatPTextDisable: #dbdbff;
$buttonsFlatPTextFocused: #4141a3;
$buttonsFlatPTextHover: #4141a3;
$buttonsFlatPTextPressed: #33337e;
$buttonsFlatPTextPrimary: #5c5ce5;
$buttonsFlatWIconDisable: #4141a3;
$buttonsFlatWIconFocused: #e3e3ff;
$buttonsFlatWIconHover: #e3e3ff;
$buttonsFlatWIconPressed: #fff;
$buttonsFlatWIconPrimary: #fff;
$buttonsFlatWTextDisable: #4141a3;
$buttonsFlatWTextFocused: #e3e3ff;
$buttonsFlatWTextHover: #e3e3ff;
$buttonsFlatWTextPressed: #fff;
$buttonsFlatWTextPrimary: #fff;
$buttonsPrimaryPBgDisable: #dbdbff;
$buttonsPrimaryPBgFocused: #4141a3;
$buttonsPrimaryPBgHover: #4141a3;
$buttonsPrimaryPBgLoading: #dbdbff;
$buttonsPrimaryPBgPressed: #33337e;
$buttonsPrimaryPBgPrimary: #5c5ce5;
$buttonsPrimaryPBorderFocused: #e3e3ff;
$buttonsPrimaryPIconLoading: gradient;
$buttonsPrimaryPIconPrimary: #fff;
$buttonsPrimaryPTextLoading: #4141a3;
$buttonsPrimaryPTextPrimary: #fff;
$buttonsPrimaryWBgDisable: #fff6;
$buttonsPrimaryWBgFocused: #f7f7fc;
$buttonsPrimaryWBgHover: #f7f7fc;
$buttonsPrimaryWBgLoading: #f7f7fc;
$buttonsPrimaryWBgPressed: #fff;
$buttonsPrimaryWBgPrimary: #fff;
$buttonsPrimaryWBorderFocused: #e3e3ff;
$buttonsPrimaryWIconDisable: #bdc1cc;
$buttonsPrimaryWIconHoverFocusedLoading: #4141a3;
$buttonsPrimaryWIconPressed: #33337e;
$buttonsPrimaryWIconPrimary: #5c5ce5;
$buttonsPrimaryWTextDisable: #bdc1cc;
$buttonsPrimaryWTextHoverFocusedLoading: #4141a3;
$buttonsPrimaryWTextPressed: #33337e;
$buttonsPrimaryWTextPrimary: #5c5ce5;
$buttonsSecondaryPBgDisable: #fff6;
$buttonsSecondaryPBgFocused: #f7f7fc;
$buttonsSecondaryPBgHover: #f7f7fc;
$buttonsSecondaryPBgPressed: #efeffc;
$buttonsSecondaryPBgPrimary: #fff;
$buttonsSecondaryPBorderDisable: #dbdbff;
$buttonsSecondaryPBorderFocused: #4141a3;
$buttonsSecondaryPBorderFocused2px: #e3e3ff;
$buttonsSecondaryPBorderHover: #4141a3;
$buttonsSecondaryPBorderLoader: #4141a3;
$buttonsSecondaryPBorderPressed: #33337e;
$buttonsSecondaryPBorderPrimary: #5c5ce5;
$buttonsSecondaryPIconDisable: #dbdbff;
$buttonsSecondaryPIconFocused: #4141a3;
$buttonsSecondaryPIconHover: #4141a3;
$buttonsSecondaryPIconPressed: #33337e;
$buttonsSecondaryPIconPrimary: #5c5ce5;
$buttonsSecondaryPTextDisable: #dbdbff;
$buttonsSecondaryPTextFocused: #4141a3;
$buttonsSecondaryPTextHover: #4141a3;
$buttonsSecondaryPTextLoading: #4141a3;
$buttonsSecondaryPTextPressed: #33337e;
$buttonsSecondaryPTextPrimary: #5c5ce5;
$buttonsSecondaryWBgPressedHovered: #191c3266;
$buttonsSecondaryWBorderDisable: #4141a3;
$buttonsSecondaryWBorderFocused: #f7f7fc;
$buttonsSecondaryWBorderFocused2px: #fff6;
$buttonsSecondaryWBorderHover: #f7f7fc;
$buttonsSecondaryWBorderLoader: #fff;
$buttonsSecondaryWBorderPressed: #ccccf7;
$buttonsSecondaryWBorderPrimary: #fff;
$buttonsSecondaryWIconDisable: #4141a3;
$buttonsSecondaryWIconFocused: #f7f7fc;
$buttonsSecondaryWIconHover: #f7f7fc;
$buttonsSecondaryWIconPressed: #ccccf7;
$buttonsSecondaryWIconPrimary: #fff;
$buttonsSecondaryWTextDisable: #4141a3;
$buttonsSecondaryWTextFocused: #f7f7fc;
$buttonsSecondaryWTextHover: #f7f7fc;
$buttonsSecondaryWTextLoading: #fff;
$buttonsSecondaryWTextPressed: #ccccf7;
$buttonsSecondaryWTextPrimary: #fff;
$colorsGradientLogo: linear-gradient(100deg, #5bd5ff 0%, #922eff 100%);
$colorsGradientWhite: linear-gradient(180deg, #fff0 64.12%, #fff 100%);
$colorsPrimaryGrey: #232732;
$colorsPrimaryPurple: #5c5ce5;
$colorsPrimaryWhite: #fff;
$colorsSecondaryGrey100: #dee0e6;
$colorsSecondaryGrey200: #cdd1d9;
$colorsSecondaryGrey300: #bdc1cc;
$colorsSecondaryGrey400: #9ba3b3;
$colorsSecondaryGrey50: #eef0f2;
$colorsSecondaryGrey500: #7a8499;
$colorsSecondaryGrey600: #596580;
$colorsSecondaryGrey700: #475166;
$colorsSecondaryGrey800: #353d4d;
$colorsSecondaryLightPurple100: #f2f2ff;
$colorsSecondaryLightPurple200: #ebebff;
$colorsSecondaryLightPurple300: #e3e3ff;
$colorsSecondaryLightPurple400: #dbdbff;
$colorsSecondaryLightPurple50: #fafaff;
$colorsSecondaryLightPurple500: #cacaff;
$colorsSecondaryLightPurple600: #b2b2ff;
$colorsSecondaryLightPurple700: #a3a3ff;
$colorsSecondaryLightPurple800: #8686ff;
$colorsSecondaryLightPurple900: #7676ff;
$colorsSecondaryPurple100: #ccccf7;
$colorsSecondaryPurple200: #b4b4f3;
$colorsSecondaryPurple25: #f7f7fc;
$colorsSecondaryPurple300: #9292ee;
$colorsSecondaryPurple400: #7d7dea;
$colorsSecondaryPurple50: #efeffc;
$colorsSecondaryPurple600: #5454d0;
$colorsSecondaryPurple700: #4141a3;
$colorsSecondaryPurple800: #33337e;
$colorsSecondaryPurple900: #272760;
$colorsTetrialBlue100: #d1e9ff;
$colorsTetrialBlue200: #b2ddff;
$colorsTetrialBlue300: #84caff;
$colorsTetrialBlue400: #53b1fd;
$colorsTetrialBlue50: #eff8ff;
$colorsTetrialBlue500: #2e90fa;
$colorsTetrialBlue600: #2a83e4;
$colorsTetrialBlue700: #2166b2;
$colorsTetrialBlue800: #194f8a;
$colorsTetrialBlue900: #133c69;
$colorsTetrialGreen100: #b4e4b4;
$colorsTetrialGreen200: #90d78f;
$colorsTetrialGreen300: #5ec45c;
$colorsTetrialGreen400: #3eb93d;
$colorsTetrialGreen50: #e7f6e7;
$colorsTetrialGreen500: #0ea70c;
$colorsTetrialGreen600: #0d980b;
$colorsTetrialGreen700: #0a7709;
$colorsTetrialGreen800: #085c07;
$colorsTetrialGreen900: #064605;
$colorsTetrialOrange100: #fff0e1;
$colorsTetrialOrange200: #ffdfc1;
$colorsTetrialOrange300: #f6bd81;
$colorsTetrialOrange400: #ff9838;
$colorsTetrialOrange50: #fff5eb;
$colorsTetrialOrange500: #e68932;
$colorsTetrialOrange600: #bf722a;
$colorsTetrialOrange700: #995b22;
$colorsTetrialOrange800: #734419;
$colorsTetrialOrange900: #593514;
$colorsTetrialRed100: #fde7e8;
$colorsTetrialRed200: #f9b3b6;
$colorsTetrialRed300: #f68e93;
$colorsTetrialRed400: #f25b62;
$colorsTetrialRed50: #fcf7f7;
$colorsTetrialRed500: #ef3b44;
$colorsTetrialRed600: #eb0a15;
$colorsTetrialRed700: #d60913;
$colorsTetrialRed800: #a7070f;
$colorsTetrialRed900: #81060c;
$colorsTransparent100: rgb(255 255 255 / 0%);
$colorsTransparentOverlay: #191c3266;
$colorsTransparentWhite40: #fff6;
