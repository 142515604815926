@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/animation";
@use "src/styles/tokens/generatedColors";

.button {
    display: inline-flex;
    padding: 0;
    border: 0;
    background-color: transparent;
    color: generatedColors.$buttonsFlatPIconPrimary;
    cursor: pointer;

    &:hover,
    &:focus-visible {
        color: generatedColors.$buttonsFlatPIconHover;
    }

    &:active {
        color: generatedColors.$buttonsFlatPIconPressed;
    }
}
