@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";

.chatMainContainer {
    width: 100%;
    height: 100%;
    margin: 0 auto !important;
}

.messagesContainer {
    max-height: calc(100% - 50px);
    overflow-y: auto;
}

.loaderContainer {
    min-height: 80px;
}
