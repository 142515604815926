@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";

.itemName {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    user-select: none;
}

.offset {
    flex-shrink: 0;
    width: 16px;
}
