@use "src/styles/tokens/generatedColors";

$customReportsBg:  linear-gradient(
        180deg,
        generatedColors.$colorsSecondaryPurple50 0%,
        generatedColors.$colorsSecondaryPurple25 10%,
        generatedColors.$colorsSecondaryPurple25 100%
    );

.reportPage {
    background: $customReportsBg
}
