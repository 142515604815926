@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/animation";
@use "src/styles/tokens/generatedColors";

.actionsMenu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.iconWrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
}
