@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";

.modal {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 100%;
    padding: 24px 36px;
    border-radius: 24px;
}
