@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";

.alert {
    width: 100%;
}

.buttons {
    width: 100%;
}

.loadingBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px auto;
}
