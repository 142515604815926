@use "src/styles/colors";
@use "src/styles/spaces";
@use "src/styles/radius";
@use "src/styles/tokens/generatedColors";

.slideoutPanel {
    display: flex;
    position: relative;
    box-sizing: border-box;
    flex: none;
    flex-direction: column;
    width: 100%;
    margin-top: 32px;
    padding: 20px 22px;
    border: 1px solid generatedColors.$colorsSecondaryGrey100;
    border-radius: radius.$m;
    background-color: generatedColors.$colorsPrimaryWhite;
}

.handle {
    display: flex;
    position: absolute;
    top: -22px;
    left: 50%;
    align-items: center;
    justify-content: center;
    gap: 2px;
    width: 44px;
    height: 11px;
    transform: translateX(-50%);
    border-radius: 5px;
    background-color: generatedColors.$colorsSecondaryPurple100;

    &:hover {
        background-color: #c2c3fb;
    }

    &.isDragging::before,
    &:hover::before {
        content: "";
        display: block;
        position: absolute;
        top: -7px;
        left: 50%;
        width: 0;
        height: 0;
        transform: translateX(-50%);
        border-right: 4px solid transparent;
        border-bottom: 4px solid #c2c3fb;
        border-left: 4px solid transparent;
    }

    &.isDragging::after,
    &:hover::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -7px;
        left: 50%;
        width: 0;
        height: 0;
        transform: translateX(-50%);
        border-top: 4px solid #c2c3fb;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
    }
}

.handeDot {
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: generatedColors.$colorsPrimaryWhite;
}

.line {
    position: absolute;
    top: -17px;
    left: 0;
    width: 100%;
    border-top: 1px dashed generatedColors.$colorsSecondaryPurple100;
    pointer-events: none;

    &.isDragging {
        border-top-color: generatedColors.$colorsSecondaryLightPurple900;
    }
}

.header {
    margin-bottom: 12px;
}

.title {
    display: flex;
    align-items: center;

    &::before {
        content: "";
        display: block;
        width: 5px;
        height: 28px;
        margin-right: 12px;
        border-radius: 20px;
        background-color: #5d6ab3;
    }
}

.closeButton {
    margin-left: auto;
}

.content {
    height: 100%;
    overflow-y: auto;
}
