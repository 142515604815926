@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/animation";
@use "src/styles/tokens/generatedColors";

.line {
    flex-shrink: 0;
    margin: 0;
    padding: 0;
    border: none;
}

.direction {
    &_horizontal {
        width: 100%;
        height: 1px;
    }

    &_vertical {
        width: 1px;
        height: 100%;
    }
}
