@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/tokens/generatedColors";

.companyUserPage {
    display: flex;
    flex-flow: column wrap;
    gap: 23px;
}

.name {
    display: flex;
    column-gap: 18px;
}
