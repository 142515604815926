@use "@/styles/colors";
@use "@/styles/radius";
@use "@/styles/spaces";
@use "src/styles/tokens/generatedColors";

.table {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    // 24px is a gap between page blocks
    max-height: calc(100vh - #{spaces.$header_height} - 24px * 2);
}

.paginationPane {
    width: calc(100% - 26px);
    padding: 6px 12px;
    border: 1px solid generatedColors.$colorsSecondaryGrey100;
    border-top: none;
    border-bottom-right-radius: radius.$m;
    border-bottom-left-radius: radius.$m;
    background: generatedColors.$colorsSecondaryPurple25;
}
