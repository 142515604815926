@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";
@use "src/styles/radius";

.results {
    overflow: auto;
    border: 1px solid generatedColors.$colorsSecondaryGrey100;

    table,
    th,
    td {
        border-collapse: collapse;
        border-style: hidden;
    }

    table {
        border-right: 1px solid generatedColors.$colorsSecondaryGrey100;
        border-bottom: 1px solid generatedColors.$colorsSecondaryGrey100;
    }

    table {
        border-collapse: collapse;
    }

    th {
        position: sticky;
        z-index: 1;
        top: 0;
        height: 40px;
        padding: 6px 12px;
        border: 1px solid generatedColors.$colorsSecondaryGrey100;
        background: generatedColors.$colorsSecondaryPurple25;
        color: generatedColors.$colorsSecondaryGrey600;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
    }

    td {
        width: 60px;
        padding: 10px 5px;
        border: 1px solid generatedColors.$colorsSecondaryGrey100;
    }

    td div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .resultsAssetNameTitle {
        min-width: 200px;
        text-align: left;
    }

    .resultsAssetName {
        align-items: center;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 20px;
    }

    .noDataCell {
        padding: 16px 12px;
    }

    .markContainer {
        min-width: 38px;
    }

    .emptyMark {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        margin: 0 2px;
        border-radius: 100px;
        background: generatedColors.$colorsSecondaryGrey100;
        color: generatedColors.$colorsPrimaryWhite;
        font-size: 12px;
        line-height: 18px;
        cursor: default;
    }

    .inProgressMark {
        background: generatedColors.$colorsTetrialOrange600;
        cursor: pointer;
    }

    .doneMark {
        background: generatedColors.$colorsTetrialGreen600;
        cursor: pointer;
    }

    .emptyDatesMark {
        background: generatedColors.$colorsTetrialGreen600;
        cursor: pointer;
    }

    .doneWithAlertsMark {
        background: generatedColors.$colorsTetrialBlue500;
        cursor: pointer;
    }

    .problemMark {
        background: generatedColors.$colorsTetrialRed500;
        cursor: pointer;
    }
}
