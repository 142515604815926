@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";
@use "src/styles/radius";
@use "src/styles/variableNames";
@import "src/styles/texts";

.table :global {
    --ag-odd-row-background-color: transparent;
    --ag-border-color: #{generatedColors.$colorsSecondaryGrey100};
    --ag-borders: solid 1px;
    --ag-border-radius: #{radius.$m};
    --ag-header-foreground-color: #{ generatedColors.$colorsSecondaryGrey600}; // Header font color
    --ag-header-background-color: #{generatedColors.$colorsSecondaryPurple25};
    --ag-cell-horizontal-padding: 12px;
    --ag-alpine-active-color: transparent;
    --ag-row-hover-color: #{generatedColors.$colorsSecondaryLightPurple100};

    // Resize element
    --ag-header-column-resize-handle-height: 100%;
    --ag-header-column-resize-handle-color: transparent;
    --ag-header-column-resize-handle-width: 20px;

    #{variableNames.$agCellBorder}: generatedColors.$colorsSecondaryGrey100;
}

.noBorders :global {
    .ag-pinned-left-header {
        --ag-borders-critical: none;
    }

    .ag-body-viewport {
        --ag-borders-critical: none;
        --ag-row-border-style: none;
    }
}

.table {
    :global(.ag-header-cell-text) {
        font-family: $fontFamily;

        @extend .text_variant_labelMediumDefault;
    }

    :global(.ag-cell-value) {
        @extend .text_variant_cell_smallTextMediumDefault;

        color: generatedColors.$colorsPrimaryGrey;
        font-family: $fontFamily;
    }

    :global(.ag-row-group .ag-icon:hover) {
        --ag-alpine-active-color: #{generatedColors.$colorsPrimaryPurple};
    }

    :global(.ag-root-wrapper) {
        border-radius: radius.$m;
    }

    // Status bar (pagination)
    :global(.ag-status-bar) {
        padding: 6px 12px;
        background-color: generatedColors.$colorsSecondaryPurple25;
    }

    // Box in status bar
    :global(.ag-react-container) {
        display: flex;
        align-items: center;
    }

    :global(.ag-row-group.ag-row-odd) {
        background-color: var(--ag-background-color);
    }

    // TODO: change color hex to token
    :global(.ag-row-group-expanded.ag-row-level-0) {
        --ag-background-color: #cacaff;
    }

    :global(.ag-row-group-expanded.ag-row-level-1) {
        --ag-background-color: #dbdbff;
    }

    :global(.ag-row-group-expanded.ag-row-level-2) {
        --ag-background-color: #e3e3ff;
    }

    :global(.ag-row-group-expanded.ag-row-level-3) {
        --ag-background-color: #ebebff;
    }

    :global(.ag-row-group-expanded.ag-row-level-4) {
        --ag-background-color: generatedColors.$colorsSecondaryLightPurple100;
    }

    :global(.ag-row-group-expanded.ag-row-level-5) {
        --ag-background-color: #fafaff;
    }
}

:global(.ag-header-group-cell-label) {
    justify-content: center;
}

:global {
    .agCell_type_string .ag-cell-wrapper {
        overflow: hidden;
    }
}
