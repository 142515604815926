@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/animation";
@use "src/styles/tokens/generatedColors";

.wrapper {
    flex-shrink: 0;
}

.open {
    border-color: generatedColors.$colorsPrimaryPurple;
}

.customIcon {
    margin-right: 6px;
}

.icon {
    margin-left: 6px;
    transition: transform animation.$speed_normal animation.$pattern_default;
}

.open .icon {
    transform: rotate(180deg);
}

.contentText {
    flex-grow: 1;
}
