@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/animation";
@use "src/styles/tokens/generatedColors";
@use "src/styles/spaces";

.subMenu {
    height: 100vh;
    margin-top: spaces.$header_height !important;
    border-top: solid 1px #{generatedColors.$colorsSecondaryGrey100};
    border-right: solid 1px #{generatedColors.$colorsSecondaryGrey100};
    background: generatedColors.$colorsPrimaryWhite;
}

.header {
    border-bottom: solid 1px #{generatedColors.$colorsSecondaryGrey100};
}
