@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";
@use "src/styles/radius";
@use "src/styles/shadows";

.userLabel {
    display: flex;
    position: relative;
    align-items: center;
    column-gap: 16px;
}

.avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: solid 1px colors.$table_hover;
    border-radius: radius.$m;
    background-color: generatedColors.$colorsSecondaryLightPurple100;
}

.time {
    position: relative;

    &::before {
        content: "•";
        display: block;
        position: absolute;
        left: -9px;
    }
}
