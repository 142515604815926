@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/animation";
@use "src/styles/tokens/generatedColors";
@use "src/pages/MainPage/UserPages/ReportsPage/CustomReportPage/CustomReportPage.module";

.reportsHome {
    background: CustomReportPage.$customReportsBg;
}

.reportsHomeImage {
  width: 600px;
  height: 430px;
  background-image: url('/public/images/reportsHomeBg.svg');
  background-repeat: no-repeat;
  background-position: center;
}

