@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";
@use "src/styles/icon";

// Base icon class
.icon {
    flex-shrink: 0;
}

.oldIcon {
    display: flex;
    align-items: center;
    justify-content: center;
}

// Icon sizes
.size {
    &_s svg,
    &_s {
        width: icon.$iconSize_s;
        height: icon.$iconSize_s;
    }

    &_m svg,
    &_m {
        width: icon.$iconSize_m;
        height: icon.$iconSize_m;
    }

    &_l svg,
    &_l {
        width: icon.$iconSize_l;
        height: icon.$iconSize_l;
    }
}

.clickable {
    cursor: pointer;
    pointer-events: auto;
}
