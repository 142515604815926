@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";
@import "src/styles/texts";

.title {
    margin: 0 0 15px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
}

.nonFieldErrors {
    margin-bottom: 10px;

    div {
        margin-bottom: 4px;
        color: generatedColors.$colorsTetrialRed500;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 16px;
}

// REFACTOR: storybook for all inputs and labels

.inputContainer {
    margin-bottom: 16px;

    .inputTitle {
        @extend .text_variant_smallTextMediumDefault;

        margin-bottom: 4px;
        color: generatedColors.$colorsSecondaryGrey600;
        font-family: $fontFamily;
    }

    .inputSelect {
        width: 100%;
        height: 32px;
        padding-left: 12px;
        border: 1px solid generatedColors.$colorsSecondaryGrey100;
        border-radius: 4px;
        outline-color: generatedColors.$colorsSecondaryGrey600;
        background: generatedColors.$colorsPrimaryWhite;
        color: generatedColors.$colorsPrimaryGrey;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;

        option {
            padding: 4px 0;
        }
    }

    .inputError {
        border: 1px solid generatedColors.$colorsTetrialRed500;
    }

    .input_disabled {
        background-color: generatedColors.$colorsPrimaryWhite;
    }

    .fieldErrorText {
        margin-top: 4px;
        color: generatedColors.$colorsTetrialRed500;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }
}

.labelContainer {
    overflow: hidden;
}

.labelTitle {
    width: 35%;
    float: left;
}

.labelText {
    margin-left: 37%;
}
