@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/animation";
@use "src/styles/tokens/generatedColors";

.headerMenu {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    padding: 0 12px;
    cursor: pointer;

    &_active {
        background-color: colors.$table_level3;
    }
}

.arrow {
    margin-left: 12px;
    transform: rotate(0deg);
    transition: tranform animation.$speed_normal animation.$pattern_default;

    &_rotated {
        transform: rotate(180deg);
    }
}

.nameBox {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: 12px;
}
