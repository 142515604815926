@use "src/styles/colors";
@use "src/styles/animation";
@use "src/styles/tokens/generatedColors";

.button {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    width: 100%;
    padding: 0;
    overflow: hidden;
    border: 0;
    outline: 0;
    background-color: transparent;
    user-select: none;
}

.open {
    border-color: generatedColors.$colorsPrimaryPurple;
}

.childrenContainer {
    flex-grow: 1;
    min-width: 0;
    text-align: start;
}

.customIcon {
    margin-right: 6px;
}

.triggerIcon {
    margin-left: 6px;
    transition: transform animation.$speed_normal animation.$pattern_default;

    &_open {
        transform: rotate(180deg);
    }
}
