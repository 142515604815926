@use "src/styles/tokens/generatedColors";
@use "src/styles/radius";
@use "src/styles/animation";
@use "src/styles/variableNames";
@use "src/styles/texts";

$smallFont: 12px;

.table :global {
    // font
    --ag-font-size: 13px;
    --ag-font-family: #{texts.$fontFamily};
    // sizes
    --ag-grid-size: 3px; // main size
    --ag-cell-horizontal-padding: 6px;
    --ag-row-height: 24px;
    --ag-header-height: 28px;
    --ag-header-column-resize-handle-width: 1px;
    // borders
    --ag-borders: solid 1px;
    --ag-borders-secondary: none;
    --ag-row-border-style: none;
    --ag-wrapper-border-radius: 8px;
    // colors
    --feb25-bg-grey: #{generatedColors.$colorsSecondaryGrey50}88; // add aplha to make it lighter
    --feb25-bg-white: #{generatedColors.$colorsPrimaryWhite};
    --feb25-bg-purple50: #{generatedColors.$colorsSecondaryPurple50};

    // nothing close to anything in our tokens
    --feb25-l0-bg-color: #5d6ab3;
    --feb25-l1-bg-color: #8895b5;
    --feb25-l2-bg-color: #e9effb;
    --ag-row-border-color: transparent;
    --ag-border-color: #{generatedColors.$colorsSecondaryGrey100};
    --ag-odd-row-background-color: transparent;
    --ag-header-background-color: #{generatedColors.$colorsPrimaryWhite};
    --ag-header-foreground-color: #{generatedColors.$colorsSecondaryGrey800};
    --ag-data-color: #{generatedColors.$colorsSecondaryGrey800};
    --ag-header-column-resize-handle-color: #{generatedColors.$colorsSecondaryGrey100};
    --ag-row-hover-color: #{generatedColors.$colorsSecondaryLightPurple100};
    --ag-alpine-active-color: #{generatedColors.$colorsTetrialBlue500};
    --ag-active-color: #{generatedColors.$colorsTetrialBlue500};
    // icons
    --ag-icon-font-family: "agGridMaterial";
    --ag-icon-size: 14px;
}

.table {
    // headers
    :global(.ag-header-row) {
        font-size: 14px;
        font-weight: 600;
    }

    :global(.ag-header-row .feb25-right-border-1) {
        --ag-header-column-resize-handle-color: transparent;
    }

    :global(.ag-header-row .header-l1) {
        background-color: var(--feb25-l1-bg-color) !important;
        color: generatedColors.$colorsPrimaryWhite;
        font-weight: 600;
    }

    :global(.header-l2) {
        background-color: var(--feb25-l2-bg-color) !important;
    }

    :global(.ag-header-row .header-hidden) {
        --ag-header-column-resize-handle-color: transparent;

        background-color: transparent !important;
        color: transparent !important;
    }

    // subheader borders
    :global(.ag-header-row:not(:first-child) .ag-header-cell:not(.ag-header-span-height.ag-header-span-total)) {
        border-top: none;
    }

    // hide filter icon when not hovered and not active
    :global(.ag-header-cell:not(:hover) .ag-header-cell-filter-button:not(.ag-filter-active)) {
        visibility: hidden;
    }

    // border classes
    :global(.feb25-right-border-1) {
        border-right: var(--ag-borders) var(--ag-border-color);
    }

    // background highlight classes
    :global(.ag-row:not(.ag-row-hover) .feb25-bg-grey) {
        background-color: var(--feb25-bg-grey);
    }

    :global(.feb25-bg-purple50) {
        background-color: var(--feb25-bg-purple50) !important;
    }

    :global(.feb25-left-border-purple) {
        border-left: 1.5px solid generatedColors.$colorsSecondaryPurple400 !important;
    }

    :global(.feb25-bg-white) {
        --ag-header-column-resize-handle-color: generatedColors.$colorsPrimaryWhite;

        border-top: none !important;
        background-color: generatedColors.$colorsPrimaryWhite;
        color: generatedColors.$colorsPrimaryGrey;
    }

    :global(.header-l0) {
        --ag-header-column-resize-handle-color: var(--feb25-l0-bg-color);

        background-color: var(--feb25-l0-bg-color) !important;
        color: generatedColors.$colorsPrimaryWhite;
    }

    // group, footer, grand total rows / bold only for 0 level groups
    :global(.ag-row-group.ag-row-level-0),
    :global(.ag-row-footer.ag-row-level-0),
    :global(.ag-row-footer.ag-row-level--1),
    :global(.feb25-grand-total-row) {
        font-weight: 600;
    }

    // group total highlight classes
    :global(.ag-row-footer:not(.ag-row-level--1) .feb25-highlight-total) {
        background-color: generatedColors.$colorsSecondaryPurple50;
    }

    :global(.ag-row-footer:not(.ag-row-level--1) .feb25-highlight-total:not(:focus-within)) {
        border-top-color: var(--ag-border-color);
    }

    // grand total highlight
    :global(.ag-row-footer.ag-row-level--1 .ag-cell) {
        padding-right: var(--ag-cell-horizontal-padding);
        padding-left: calc(
            var(--ag-cell-horizontal-padding) + var(--ag-row-group-indent-size) * var(--ag-indentation-level)
        );
        border-bottom-color: generatedColors.$colorsPrimaryWhite;
    }

    :global(.ag-row-footer.ag-row-level--1 .feb25-highlight-total),
    :global(.ag-row.feb25-grand-total-row .feb25-highlight-total) {
        background-color: generatedColors.$colorsTetrialBlue100;
        color: generatedColors.$colorsTetrialBlue600;
    }

    // row classes
    :global(.feb25-italic-row) {
        font-style: italic;
    }

    :global(.feb25-small-font-row) {
        font-size: $smallFont;
    }

    :global(.feb25-separator-row) {
        color: transparent;
    }

    // to overide subheader border in some cases
    :global(.feb25-no-top-border) {
        border-top: none !important;
    }

    // cells
    :global(.feb25-clickable) {
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    // active filter icon with blue dot
    :global(.ag-filter-active) {
        position: relative;
    }

    :global(.ag-icon-filter) {
        z-index: 1;
    }

    :global(.ag-filter-active::before) {
        content: "";
        position: absolute;
        z-index: 2;
        top: -2px;
        right: -2px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: generatedColors.$colorsPrimaryWhite;
    }

    :global(.ag-filter-active::after) {
        content: "";
        position: absolute;
        z-index: 3;
        top: -1px;
        right: -1px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: var(--ag-active-color);
    }

    :global(.feb25-indent-left) {
        padding-left: 35px;
    }
}
