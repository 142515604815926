@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 188px;
}

.slot {
    width: 100%;
}

.buttons {
    display: flex;
    justify-content: space-between;
}
