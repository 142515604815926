@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";

.list {
    display: block;
    position: relative;
    max-height: 100%;
    margin: 0;
    padding: 0;
    overflow: auto;
    list-style-type: none;
    outline: none;
}
