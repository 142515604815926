@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/tokens/generatedColors";

.filters {
    flex-grow: 1;
}

.headerBox {
    display: flex;
    padding-bottom: 24px;
}

.tableRow {
    cursor: pointer;
}
