@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/tokens/generatedColors";
@import "src/styles/texts";

.headerNavigation {
    display: flex;
    position: relative;

    // For overlap header bottom border on active tab
    bottom: -1px;
    align-items: flex-end;
    height: 100%;
    column-gap: 4px;
}

.link {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    overflow: hidden;
    border: solid 1px generatedColors.$colorsSecondaryGrey100;
    border-top-left-radius: radius.$m;
    border-top-right-radius: radius.$m;
    outline: none;
    background-color: generatedColors.$colorsSecondaryPurple25;
    color: generatedColors.$colorsSecondaryGrey600;
    font-family: $fontFamily;
    text-decoration: inherit;
    text-overflow: ellipsis;
    white-space: nowrap;

    @extend .text_variant_bodyMedium;

    &_active {
        border-bottom-color: generatedColors.$colorsPrimaryWhite;
        background-color: transparent;
        color: generatedColors.$colorsPrimaryPurple;
    }
}
