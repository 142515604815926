@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/icon";
@use "src/styles/tokens/generatedColors";
@import "src/styles/texts";

// SIZE variable names
$iconTextGapVarName: --intelas-button-icon-and-text-gap;
$iconSizeVarName: --intelas-button-icon-size;
$horizontalPaddingVarName: --intelas-button-horizontal-padding;
$verticalPaddingVarName: --intelas-button-vertical-padding;

// THEME COLORS variable names
// 1. Colors for text/borders/icon
$mainColorVarName: --intelas-button-main-color;
$mainColorHoveredVarName: --intelas-button-main-hover-color;
$mainColorFocusedVarName: --intelas-button-main-focus-color;
$mainColorPressedVarName: --intelas-button-main-pressed-color;
// 2. Colors for BG
$bgColorVarName: --intelas-button-bg-color;
$bgColorHoveredVarName: --intelas-button-bg-hover-color;
$bgColorFocusedVarName: --intelas-button-bg-focus-color;
$bgColorActiveVarName: --intelas-button-bg-pressed-color;

.button {
    display: inline-flex;
    position: relative;
    box-sizing: border-box;
    flex-shrink: 0;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    width: min-content;
    padding: var($verticalPaddingVarName) var($horizontalPaddingVarName);
    overflow: hidden;
    border-width: 1px;
    border-style: solid;
    border-radius: radius.$m;
    border-color: transparent;
    outline: none;
    background-color: var($bgColorVarName);
    box-shadow: none;
    color: var($mainColorVarName);
    font-family: $fontFamily;
    text-overflow: ellipsis;
    text-shadow: none;
    white-space: nowrap;
    cursor: pointer;
    pointer-events: auto;
    user-select: none;

    &:hover {
        background-color: var($bgColorHoveredVarName);
        color: var($mainColorHoveredVarName);
    }

    &:focus {
        background-color: var($bgColorFocusedVarName);
        color: var($mainColorFocusedVarName);
    }

    &:active {
        background-color: var($bgColorActiveVarName);
        color: var($mainColorPressedVarName);
    }
}

.iconOnly {
    // same paddings for icon only button
    padding: var($verticalPaddingVarName);
}

.icon,
.icon svg {
    width: var($iconSizeVarName) !important;
    height: var($iconSizeVarName) !important;
    color: inherit;
    pointer-events: auto;
}

.size {
    &_xs {
        #{$verticalPaddingVarName}: 4px;
        #{$iconSizeVarName}: icon.$iconSize_s;

        // FIXME: Uncomment this variables when button with text will be implemented
        //  For now xs size used only for icon only buttons
        //#{$horizontalPaddingVarName}: 8px;
        //#{$iconTextGapVarName}: 8px;
        //@extend .text_variant_smallTextSemiboldDefault;
    }

    &_s {
        #{$verticalPaddingVarName}: 5px;
        #{$horizontalPaddingVarName}: 16px;
        #{$iconTextGapVarName}: 8px;
        #{$iconSizeVarName}: icon.$iconSize_m;

        @extend .text_variant_smallTextSemiboldDefault;
    }

    &_l {
        #{$verticalPaddingVarName}: 8px;
        #{$horizontalPaddingVarName}: 16px;
        #{$iconTextGapVarName}: 8px;
        #{$iconSizeVarName}: icon.$iconSize_l;

        @extend .text_variant_bodySemiboldDefault;
    }
}

.theme {
    &_primary {
        #{$mainColorVarName}: generatedColors.$colorsPrimaryWhite;
        #{$mainColorHoveredVarName}: generatedColors.$colorsPrimaryWhite;
        #{$mainColorFocusedVarName}: generatedColors.$colorsPrimaryWhite;
        #{$mainColorPressedVarName}: generatedColors.$colorsPrimaryWhite;
        #{$bgColorVarName}: generatedColors.$colorsPrimaryPurple;
        #{$bgColorHoveredVarName}: colors.$primary_darkPurple;
        #{$bgColorFocusedVarName}: colors.$primary_darkPurple;
        #{$bgColorActiveVarName}: colors.$primary_pressedPurple;
    }

    &_secondary {
        #{$mainColorVarName}: generatedColors.$colorsPrimaryPurple;
        #{$mainColorHoveredVarName}: colors.$primary_darkPurple;
        #{$mainColorFocusedVarName}: colors.$primary_darkPurple;
        #{$mainColorPressedVarName}: colors.$primary_darkPurple;
        #{$bgColorVarName}: transparent;
        #{$bgColorHoveredVarName}: generatedColors.$colorsSecondaryPurple25;
        #{$bgColorFocusedVarName}: transparent;
        #{$bgColorActiveVarName}: generatedColors.$colorsSecondaryLightPurple100;
    }

    &_flat {
        #{$mainColorVarName}: generatedColors.$colorsPrimaryPurple;
        #{$mainColorHoveredVarName}: colors.$primary_darkPurple;
        #{$mainColorFocusedVarName}: colors.$primary_darkPurple;
        #{$mainColorPressedVarName}: colors.$primary_pressedPurple;
        #{$bgColorVarName}: transparent;
        #{$bgColorHoveredVarName}: transparent;
        #{$bgColorFocusedVarName}: transparent;
        #{$bgColorActiveVarName}: transparent;
        #{$verticalPaddingVarName}: 0;
        #{$horizontalPaddingVarName}: 0;
    }

    &_destructive {
        #{$mainColorVarName}: generatedColors.$colorsPrimaryWhite;
        #{$mainColorHoveredVarName}: generatedColors.$colorsPrimaryWhite;
        #{$mainColorFocusedVarName}: generatedColors.$colorsPrimaryWhite;
        #{$mainColorPressedVarName}: generatedColors.$colorsPrimaryWhite;
        #{$bgColorVarName}: generatedColors.$colorsTetrialRed400;
        #{$bgColorHoveredVarName}: generatedColors.$colorsTetrialRed500;
        #{$bgColorFocusedVarName}: generatedColors.$colorsTetrialRed500;
        #{$bgColorActiveVarName}: colors.$status_redPressed;
    }

    &_alert {
        #{$mainColorVarName}: colors.$status_blue;
        #{$mainColorHoveredVarName}: colors.$status_blue;
        #{$mainColorFocusedVarName}: colors.$status_darkBlue;
        #{$mainColorPressedVarName}: colors.$status_darkBlue;
        #{$bgColorVarName}: transparent;
        #{$bgColorHoveredVarName}: generatedColors.$colorsSecondaryLightPurple50;
        #{$bgColorFocusedVarName}: transparent;
        #{$bgColorActiveVarName}: transparent;
    }

    &_primaryInverted {
        #{$mainColorVarName}: generatedColors.$colorsPrimaryPurple;
        #{$mainColorHoveredVarName}: colors.$primary_darkPurple;
        #{$mainColorFocusedVarName}: colors.$primary_darkPurple;
        #{$mainColorPressedVarName}: colors.$primary_pressedPurple;
        #{$bgColorVarName}: generatedColors.$colorsPrimaryWhite;
        #{$bgColorHoveredVarName}: generatedColors.$colorsSecondaryPurple25;
        #{$bgColorFocusedVarName}: generatedColors.$colorsSecondaryLightPurple200;
        #{$bgColorActiveVarName}: generatedColors.$colorsPrimaryWhite;
    }

    &_flatInverted {
        #{$mainColorVarName}: generatedColors.$buttonsFlatWIconFocused;
        #{$mainColorHoveredVarName}: generatedColors.$colorsSecondaryLightPurple100;
        #{$mainColorFocusedVarName}: generatedColors.$colorsSecondaryLightPurple100;
        #{$mainColorPressedVarName}: generatedColors.$colorsPrimaryWhite;
        #{$bgColorVarName}: transparent;
        #{$bgColorHoveredVarName}: transparent;
        #{$bgColorFocusedVarName}: transparent;
        #{$bgColorActiveVarName}: transparent;
        #{$verticalPaddingVarName}: 0;
        #{$horizontalPaddingVarName}: 0;
    }

    &_secondaryInverted {
        #{$mainColorVarName}: generatedColors.$colorsPrimaryWhite;
        #{$mainColorHoveredVarName}: generatedColors.$colorsPrimaryWhite;
        #{$mainColorFocusedVarName}: generatedColors.$colorsPrimaryWhite;
        #{$mainColorPressedVarName}: generatedColors.$colorsSecondaryPurple100;
        #{$bgColorVarName}: transparent;
        #{$bgColorHoveredVarName}: colors.$background_secondaryWhite_pressedHovered;
        #{$bgColorFocusedVarName}: colors.$background_secondaryWhite_pressedHovered;
        #{$bgColorActiveVarName}: transparent;
    }
}

.variant {
    &_text {
        &:hover {
            text-decoration: underline;
        }

        &:active {
            text-decoration: underline;
        }
    }

    &_outlined {
        border-color: var($mainColorVarName);

        &:hover {
            border-color: var($mainColorHoveredVarName);
        }

        &:focus {
            border-color: var($mainColorFocusedVarName);
        }

        &:active {
            border-color: var($mainColorPressedVarName);
        }
    }

    &_contained {} // stylelint-disable-line block-no-empty
}

.disabled {
    opacity: 0.3;
    cursor: default;
}

.iconWithText {
    column-gap: var($iconTextGapVarName);
}

.loading {
    cursor: default;
}

.block {
    justify-content: center;
    width: 100%;
}
