@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/tokens/generatedColors";

.monthSelect {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 24px;
    padding: 6px 0;
    border-top: 1px solid generatedColors.$colorsSecondaryGrey100;
    border-right: 1px solid generatedColors.$colorsSecondaryGrey100;
    border-left: 1px solid generatedColors.$colorsSecondaryGrey100;
    border-top-left-radius: radius.$m;
    border-top-right-radius: radius.$m;
    background: generatedColors.$colorsSecondaryPurple25;

    .monthName {
        margin: 0 16px;
        color: generatedColors.$colorsSecondaryGrey600;
        font-size: 14px;
        font-weight: 500;
    }
}
