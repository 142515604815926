@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";
@import "src/styles/texts";

.navigateButtonCell {
    @extend .text_variant_cell_smallTextMediumDefault;

    overflow: hidden;
    color: generatedColors.$colorsPrimaryPurple;
    font-family: $fontFamily;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}
