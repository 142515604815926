@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loader {
    margin: auto;
}
