@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/animation";
@use "src/styles/tokens/generatedColors";

.reportTableWrapper {
    box-sizing: border-box;
    flex-grow: 1;
    border: solid 1px generatedColors.$colorsSecondaryGrey100;
    border-radius: radius.$l;
    background: generatedColors.$colorsPrimaryWhite;
    box-shadow: shadows.$elevation03;

    &:hover {
        border: solid 1px generatedColors.$colorsPrimaryPurple;
    }
}

.line {
    border-radius: radius.$l;
    background:  generatedColors.$colorsSecondaryLightPurple900;
}
