@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/texts";
@use "src/styles/animation";

.tabs {
    @extend .text_variant_smallTextMediumDefault;

    display: flex;
    position: relative;
    padding: 0 36px;
    column-gap: 16px;
    border-bottom: 1px solid generatedColors.$colorsSecondaryGrey100;
    color: generatedColors.$colorsSecondaryGrey600;
    font-family: texts.$fontFamily;
    user-select: none;
}

.noPadding {
    padding: 0;
}

.noBorderBottom {
    border-bottom: none;
}

.tab {
    padding: 18px 0 17px;
    cursor: pointer;

    &:hover {
        color: generatedColors.$colorsPrimaryPurple;
    }
}

.tab_noTopPadding {
    padding-top: 0;
}

.active {
    color: generatedColors.$colorsPrimaryPurple;

    &:hover {
        cursor: auto;
    }
}

.underline {
    position: absolute;
    bottom: 0;
    height: 4px;
    transition: width animation.$speed_fast ease-in, left animation.$speed_fast animation.$pattern_default;
    border-radius: 10px 10px 0 0;
    background: generatedColors.$colorsPrimaryPurple;
}
