@use "./radius";
@use "./tokens/generatedColors";
@import "./texts";

.type {
    &_info {
        border-color: generatedColors.$colorsPrimaryPurple;
        background-color: generatedColors.$colorsSecondaryPurple25;
        color: generatedColors.$colorsPrimaryGrey;

        .icon {
            color: generatedColors.$colorsSecondaryPurple700;
        }
    }

    &_warning {
        border-color: generatedColors.$colorsTetrialOrange400;
        background-color: generatedColors.$colorsTetrialOrange50;
        color: generatedColors.$colorsTetrialOrange800;

        .icon {
            color: generatedColors.$colorsTetrialOrange600;
        }
    }

    &_error {
        border-color: generatedColors.$colorsTetrialRed500;
        background-color: generatedColors.$colorsTetrialRed50;
        color: generatedColors.$colorsTetrialRed600;
    }

    &_alert {
        border-color: generatedColors.$colorsTetrialBlue700;
        background-color: generatedColors.$colorsTetrialBlue50;
        color: generatedColors.$colorsTetrialBlue700;
    }

    &_success {
        border-color: generatedColors.$colorsTetrialGreen600;
        background-color: generatedColors.$colorsTetrialGreen50;
        color: generatedColors.$colorsPrimaryGrey;

        .icon {
            color: generatedColors.$colorsTetrialOrange700;
        }
    }

    &_neutral {
        border-color: generatedColors.$colorsSecondaryGrey100;
        background-color: generatedColors.$colorsSecondaryPurple25;
        color: generatedColors.$colorsPrimaryGrey;
    }
}
