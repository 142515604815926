@use "src/styles/colors";
// Table style Overrides for TableBuilder component
@use "src/styles/tokens/generatedColors";

.table :global {
    // Tool Panel
    --ag-control-panel-background-color: #{generatedColors.$colorsSecondaryPurple25};
    --ag-grid-size: 5px;

    // Filter list in Tool Panel
    .ag-set-filter-list {
        height: fit-content;
    }

    // Custom filter panel in Tool Panel
    .ag-react-container {
        flex-grow: 1;
        max-width: 100%;
    }

    // Border in header
    :global(.ag-header-viewport .ag-header-cell.rightBorder1),
    :global(.ag-header-viewport .ag-header-group-cell.rightBorder1) {
        border-right: var(--ag-borders) var(--ag-border-color);
    }
}
