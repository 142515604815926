@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/tokens/generatedColors";

.detailsBox {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 1px solid generatedColors.$colorsSecondaryGrey100;
    border-radius: radius.$m;
}

.title {
    display: flex;
    align-items: center;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
    padding: 13px 15px;
    border-bottom: 1px solid generatedColors.$colorsSecondaryGrey100;
    background: generatedColors.$colorsSecondaryPurple25;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 15px;
}
