@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/tokens/generatedColors";
@import "src/styles/texts";

.inputBox {
    width: fit-content;
}

.label {
    width: fit-content;
    color: generatedColors.$colorsPrimaryGrey;
    font-family: $fontFamily;
    white-space: nowrap;

    @extend .text_variant_smallTextMediumDefault;

    &_active {
        color: generatedColors.$colorsPrimaryPurple;
        text-decoration: underline;
    }

    &:hover {
        color: generatedColors.$colorsPrimaryPurple;
        text-decoration: underline;
    }
}

.icon {
    margin-left: 4px;
}

.dash {
    margin: 0 8px;
}
