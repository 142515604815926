/*
 * Border radius constants
 * Import example: @use 'src/styles/radius';
 * Usage example: radius.$s
*/

$s: 2px;
$m: 4px;
$l: 8px;
$xl: 12px;
