@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/zIndex";
@use "src/styles/tokens/generatedColors";

.sideModal {
    display: flex;
    flex-direction: column;
    width: 500px;
    height: 100%;
    background-color: generatedColors.$colorsPrimaryWhite;
    box-shadow: shadows.$dropdownElevation;
}

.header {
    padding: 18px 18px 18px 24px;
    box-shadow: shadows.$dropdownElevation;
}

.title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;

    .bodyContent {
        display: flex;
        flex: 1;
        flex-direction: column;
        height: fit-content;
        margin: 24px 32px;
    }

    .bodyContentNoPadding {
        margin: 0;
    }
}

.buttons {
    display: flex;
    padding: 24px 32px;
    column-gap: 20px;
    box-shadow: shadows.$dropdownElevation;
}
