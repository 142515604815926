/*
 * z-index constants
 * Import example: @use "src/styles/zIndex";
 * Usage example: zIndex.$baseModal
*/
$datePickerPopover: 800;
$sideModal: 900;
$baseModal: 1000;
$popover: 2000;
$imageViewer: 100;
