@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/tokens/generatedColors";
@import "src/styles/texts";
@import "src/styles/alertTypes";

$contentMaxWidth: 652px;

.alert {
    display: flex;
    box-sizing: border-box;
    flex-grow: 1;
    border-width: 1px;
    border-style: solid;
    border-radius: radius.$m;
}

.centered {
    justify-content: center;

    .content {
        max-width: $contentMaxWidth;
    }
}

.content {
    margin: 0;
    font-family: $fontFamily;
    word-break: break-word;
}

.size {
    &_l {
        padding: 16px;
        gap: 12px;

        .content {
            @extend .text_variant_bodyMedium;
        }
    }

    &_s {
        padding: 8px;
        gap: 8px;

        .content {
            @extend .text_variant_smallTextMediumDefault;
        }
    }
}
