@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";
@use "src/styles/variableNames";

$agCell_sideBorder: solid 1px var(#{variableNames.$agCellBorder});

.rightBorder {
    // For cell border
    --ag-cell-horizontal-border: #{$agCell_sideBorder};

    // For header border
    border-right: $agCell_sideBorder;
}
