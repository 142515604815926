@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";

div.table_variant_2 {
    .ag-root-wrapper {
        border-radius: 4px;
        border-color: #e8e8fc;
    }

    .ag-header {
        border-bottom-color: #e8e8fc;
        background: generatedColors.$colorsSecondaryPurple25;
    }

    .ag-header-cell-label {
        color: generatedColors.$colorsSecondaryGrey600;
        font-family: sans-serif, Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
    }

    .ag-row {
        border-bottom-color: #e8e8fc;
    }

    .ag-cell {
        color: #000;
        font-family: sans-serif, Inter;
        font-size: 16px;
        font-weight: 400;
    }

    .ag-cell-focus {
        border-color: rgb(0 0 0 / 0%) !important;
    }

    // ag grid 2

    .ag-root-wrapper {
        border: none;
    }

    .ag-header-row-column-group {
        border-bottom: 1px solid generatedColors.$colorsPrimaryWhite;
        background: #e8e8fc;

        .ag-header-group-cell-label {
            justify-content: center;
            font-family: sans-serif, Inter;
            font-weight: 700;
        }

        .ag-header-group-cell .ag-header-cell-resize::after {
            top: 0;
            width: 1px;
            height: 33px;
            background-color: generatedColors.$colorsPrimaryWhite;
        }
    }

    .ag-header-row:not(:first-child) .ag-header-cell {
        border: none;
    }

    .ag-header-cell-label {
        color: generatedColors.$colorsSecondaryGrey600;
        font-weight: 700;
    }

    .ag-cell {
        font-size: 12px;
    }

    .ag-row.ag-row-selected .ag-cell,
    .ag-row-even.ag-row-hover .ag-cell,
    .ag-row-odd.ag-row-hover .ag-cell {
        background-color: #dbdbff;
    }

    .ag-row-odd {
        background: generatedColors.$colorsSecondaryPurple25;
    }

    .ag-row {
        border-bottom: none;
    }

    *::-webkit-scrollbar:horizontal {
        width: 12px;
        background-color: white;
    }

    *::-webkit-scrollbar-thumb:horizontal {
        border: 3px solid generatedColors.$colorsPrimaryWhite;
        border-radius: 15px;
        background-color: white;
    }
}
