@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/spaces";
@use "src/styles/tokens/generatedColors";

.header {
    display: flex;
    flex-shrink: 0;
    height: spaces.$header_height;
    padding: 0 22px 0 spaces.$mainPage_horizontalPadding;
    border-bottom: solid 1px generatedColors.$colorsSecondaryGrey100;
}

.leftContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
