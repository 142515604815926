@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/animation";
@use "src/styles/tokens/generatedColors";

$borderWidth: 3px;
$shadowFilter: drop-shadow(0 0 32px #0003);

.wrapper {
    display: flex;
    position: relative;
    height: 100%;
}

.gradientLayer {
    display: flex;
    flex-grow: 1;
    padding: $borderWidth;
    border-top-left-radius: radius.$xl;
    border-bottom-left-radius: radius.$xl;
    background: linear-gradient(
        to right,
        generatedColors.$colorsSecondaryLightPurple800,
        generatedColors.$colorsPrimaryWhite
    );
    filter: $shadowFilter;
}

.content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: stretch;
    overflow: hidden;
    border-top-left-radius: calc(radius.$xl - $borderWidth);
    border-bottom-left-radius: calc(radius.$xl - $borderWidth);
    background-color: generatedColors.$colorsPrimaryWhite;
}

.underLayer {
    position: absolute;
    width: 16px;
    border-top-left-radius: radius.$xl;
    border-bottom-left-radius: radius.$xl;
    background-color: generatedColors.$colorsPrimaryWhite;
    filter: $shadowFilter;
}

.closeButton {
    align-self: flex-start;
}
