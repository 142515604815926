@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/animation";
@use "src/styles/tokens/generatedColors";

.text {
    flex-grow: 1;
}

.icon {
    flex-shrink: 0;
}

.cell {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    width: 100%;
    padding: 0;
    overflow: hidden;
    border: 0;
    outline: 0;
    background-color: transparent;
    cursor: pointer;
    user-select: none;
    gap: 4px;
}
