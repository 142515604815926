@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";

.readonly {
    flex-direction: column;
    align-items: flex-start;
    padding: 4px 12px 12px;
    border: 1px solid generatedColors.$colorsSecondaryGrey100;
    border-radius: 4px;
    background: generatedColors.$colorsSecondaryPurple25;

    .treeItem {
        display: flex;
        flex-direction: row;
        margin-top: 8px;
        color: generatedColors.$colorsSecondaryGrey600;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;

        path {
            stroke: generatedColors.$colorsSecondaryGrey600;
        }

        &.newItemPlaceholder {
            display: inline-block;
            padding: 2px 8px;
            border-radius: 2px;
            background: #e3e3ff;
            color: generatedColors.$colorsPrimaryPurple;
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
        }

        &.lastItem {
            color: generatedColors.$colorsPrimaryGrey;

            path {
                stroke: generatedColors.$colorsPrimaryGrey;
            }
        }
    }

    .categoryName {
        margin-left: 8px;
    }
}

.picker {
    height: 300px;
    padding: 6px 10px;
    overflow-y: auto;
    border: 1px solid generatedColors.$colorsSecondaryGrey100;
    border-radius: 4px;

    .pickerItem {
        .pickerItemName {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 3px 0;
            color: generatedColors.$colorsPrimaryGrey;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            cursor: pointer;

            &:hover {
                background: #f3f3f3;
            }

            &.pickerItemSelected {
                background: #e8e8fc;
            }

            .pickerItemIcon {
                width: 20px;
                height: 20px;
            }

            .bullet {
                margin-right: 6px;
                // width: 6px;
                // height: 6px;
                // background: generatedColors.$colorsPrimaryGrey;
                // border-radius: 3px;
                margin-left: 7px;
            }

            &_disabled {
                color: generatedColors.$colorsSecondaryGrey400;
            }
        }
    }
}
