@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/icon";
@use "src/styles/select";

$optionGap: 8px;

.option {
    display: flex;
    position: relative;
    box-sizing: border-box;
    flex-grow: 1;
    align-items: center;
    width: 100%;
    min-height: select.$optionHeight;
    padding: 10px 12px;
    border: 0;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    gap: $optionGap;
    user-select: none;
}

.text {
    flex-grow: 1;
    // Need to calc if for left some space for default 'Icon'
    max-width: calc(100% - #{$optionGap} - #{icon.$iconSize_m});
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}

.active {
    background-color: generatedColors.$colorsSecondaryLightPurple100;
}

.disabled {
    cursor: default;
}
