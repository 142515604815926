@use "src/styles/colors";
@use "src/styles/spaces";
@use "src/styles/sizes";
@use "src/styles/tokens/generatedColors";

body,
#root,
.app {
    height: 100vh;
    margin: 0;
    overflow: hidden;
}

.main__center__content {
    display: flex;
    flex: auto;
    flex-direction: column;
    overflow: auto;
}

/* TODO: move scrollbar styles to global css */
*::-webkit-scrollbar {
    width: 12px;
    background-color: white;
}

*::-webkit-scrollbar-thumb {
    border: 3px solid generatedColors.$colorsPrimaryWhite;
    border-radius: 15px;
    background-color: generatedColors.$colorsPrimaryPurple;
}

/** Filter pane **/

.page-filters {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    padding: 0 0 20px;
    gap: 20px 12px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    text-align: left;

    &.two-lines {
        &:nth-child(1) {
            padding: 0 0 16px;

            .filters-line-bg {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                padding: 16px;
                gap: 20px 8px;
                border: 1px solid generatedColors.$colorsSecondaryGrey100;
                border-radius: 4px;
                background: generatedColors.$colorsSecondaryPurple25;
            }
        }

        &:nth-child(2) {
            padding: 0 0 24px;
        }
    }

    .filter-container {
        &.filter-container-hidden {
            display: none;
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &--expand {
            flex: 1;
        }

        &--right {
            text-align: right;
        }

        .filter-title {
            margin-bottom: 8px;
            color: generatedColors.$colorsSecondaryGrey400;
            font-size: 12px;
            font-weight: 600;
            line-height: 18px;
        }

        .expand-collapse {
            display: flex;
            flex-direction: row;
            color: generatedColors.$colorsSecondaryGrey600;
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;

            .expand-btn {
                padding-right: 16px;
                border-right: 1px solid generatedColors.$colorsSecondaryGrey100;
            }

            .collapse-btn {
                padding-left: 16px;
            }

            .expand-btn,
            .collapse-btn {
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }

            &.on-filters-pane {
                margin-left: 16px;
            }
        }
    }

    .sep {
        flex: auto;
    }

    svg {
        fill: generatedColors.$colorsSecondaryGrey400;
    }
}

/** Dialog **/
// REFACTOR: remove it!
.page-dialog-content {
    position: relative;
    margin: 24px;

    .page-dialog-overlay {
        display: flex;
        position: absolute;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: rgb(255 255 255 / 70%);
    }

    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        margin-bottom: 24px;
        color: generatedColors.$colorsPrimaryGrey;

        svg {
            fill: generatedColors.$colorsPrimaryGrey;
        }

        .icon-left {
            width: 16px;
            height: 14px;
            cursor: pointer;
        }

        .title {
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
        }

        .sep {
            flex: auto;
        }

        .icon-close {
            width: 12px;
            height: 12px;
            cursor: pointer;
        }
    }

    .body {
        // margin-bottom: 24px;

        .alert-text {
            color: generatedColors.$colorsSecondaryGrey600;
        }
    }
}

/* stylelint-disable selector-class-pattern */

/** MUI popover **/

div.MuiPaper-root {
    box-shadow: 0 2px 16px rgb(25 28 50 / 16%);

    .css-10hburv-MuiTypography-root {
        color: generatedColors.$colorsPrimaryGrey;
        font-family: sans-serif, Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }
}

/** MUI menu **/

.MuiTabs-root {
    .MuiButtonBase-root {
        font-family: sans-serif, Inter;
        font-size: 16px;
        font-weight: 600;
        text-transform: none;

        &.Mui-selected {
            color: generatedColors.$colorsPrimaryPurple;
        }
    }

    .MuiTabs-indicator {
        background: generatedColors.$colorsPrimaryPurple;
    }
}
