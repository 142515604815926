@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";
@use "src/styles/radius";
@use "src/styles/sizes";
@use "src/styles/animation";
@use "src/styles/shadows";

.menu {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    width: sizes.$sideMenu_small_width;
    height: 100%;
    overflow: hidden;
    transition: width animation.$speed_fast ease-in, left animation.$speed_normal animation.$pattern_default;
    border-right: solid 1px #{generatedColors.$colorsSecondaryGrey100};
    background: generatedColors.$colorsSecondaryPurple25;
    user-select: none;

    &_expanded {
        width: 250px;
        width: sizes.$sideMenu_width;
        box-shadow: shadows.$elevation01;
    }
}
