@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";

.uploadArea {
    display: flex;
    height: 414px;
    margin-bottom: 54px;
}

.uploadText {
    display: flex;
    flex-direction: column;
    width: 360px;
    gap: 18px;
}
