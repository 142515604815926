/* stylelint-disable */
@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";
@use "src/styles/radius";
@use "src/styles/shadows";
@import "src/styles/texts";

.companyUserActiveStatusCell {
    display: flex;
}

.item {
    width: auto;
    height: 20px;
    margin: 4px;
    padding: 2px 10px;
    border-radius: 2px;

    font-family: $fontFamily;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
}

.active{
    color: #00876F;
    background: colors.$background_green;
    border: 1px solid #50C7B2;
}
.inactive{
    color: #e54545;
    background: #FCFAF7;
    border: 1px solid  #e54545;
}
