@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/tokens/generatedColors";

.headerMenuItem {
    padding: 14px 16px !important;

    &_active {
        background-color: generatedColors.$colorsSecondaryPurple25;
    }
}
