@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";
@use "@/styles/animation";
@use "src/styles/shadows";

.wrapper {
    display: grid;
    grid-template-rows: 0fr;
    transition:
        grid-template-rows animation.$speed_normal ease-out,
        opacity animation.$speed_normal ease-out;
    opacity: 0;
}

.isOpen {
    grid-template-rows: 1fr;
    opacity: 1;
}

.content {
    overflow: hidden;
}

.header {
    display: grid;
    grid-template-columns: 24px 1fr;
    align-items: center;
    gap: 4px;
    width: 100%;
    padding: 8px;
    border: none;
    background-color: generatedColors.$colorsSecondaryPurple25;
    text-align: left;
    cursor: pointer;

    &:hover {
        background-color: generatedColors.$colorsSecondaryPurple50;
    }

    &:focus,
    &:active,
    &:focus-visible {
        @include shadows.boxShadowInner(generatedColors.$colorsSecondaryLightPurple200);

        outline: none;
        background-color: generatedColors.$colorsSecondaryPurple50;
    }
}

.icon {
    transition: transform animation.$speed_fast ease-out;

    &_rotated {
        transform: rotate(90deg);
    }
}
