@use "@/styles/colors";
@use "src/styles/tokens/generatedColors";

.container {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 16px 24px;
}

.head {
    margin: 12px 0 16px;
    border-bottom: 1px solid generatedColors.$colorsSecondaryGrey100;

    .headRow {
        display: flex;
        flex-direction: row;
        margin-bottom: 12px;

        .headRowCell {
            display: flex;
            flex-direction: row;
            width: 50%;

            .headRowCellTitle {
                width: 40%;
            }

            .headRowCellValue {
                width: calc(60% - 25px);
                padding-right: 12px;
                padding-left: 12px;

                //border-left: 1px solid generatedColors.$colorsSecondaryGrey100;
            }
        }
    }
}

.goToDetailsLink {
    font-size: 14px;
}

.errorsTable {
    margin-top: 16px;
}

.errorTextCell {
    padding: 6px 0;
    word-wrap: normal;
    word-break: normal;
}

.modalContent {
    display: flex;
    flex: 1;
    flex-direction: column;
    row-gap: 16px;

    .contentTopPane {
        display: flex;
        flex-direction: row;
        align-items: center;

        .infoIconContainer {
            position: relative;
            top: 1px;
            margin-right: 6px;
        }

        .sep {
            flex: 1;
        }
    }
}
