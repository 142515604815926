@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/animation";
@use "src/styles/tokens/generatedColors";

.filters {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.filters.required {
    padding: 16px;
    border: 1px solid generatedColors.$colorsSecondaryGrey100;
    border-radius: radius.$m;
    background: generatedColors.$colorsSecondaryPurple25;
}

.inputsList {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    align-items: flex-end;
}
