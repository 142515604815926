@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/tokens/generatedColors";
@import "src/styles/texts";

$bGcolorVarName: --intelas-label-bg-color;
$strokeColorVarName: --intelas-label-stroke-color;
$textColorVarName: --intelas-label-text-color;

.label {
    display: inline-flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    padding: 5px 8px;
    column-gap: 4px;
    border-width: 1px;
    border-style: solid;
    border-radius: radius.$s;
    border-color: var($strokeColorVarName);
    background-color: var($bGcolorVarName);
    color: var($textColorVarName);
    font-family: $fontFamily;
    white-space: nowrap;

    @extend .text_variant_labelSemiboldDefault;
}

.clickable {
    cursor: pointer;

    &:hover {
        background-color: var($strokeColorVarName);
        color: generatedColors.$colorsPrimaryWhite;
    }
}

.color {
    &_green {
        #{$strokeColorVarName}: colors.$stroke_green;
        #{$bGcolorVarName}: colors.$background_green;
        #{$textColorVarName}: colors.$status_darkGreen;
    }

    &_orange {
        #{$strokeColorVarName}: colors.$stroke_orange;
        #{$bGcolorVarName}: generatedColors.$colorsTetrialRed50;
        #{$textColorVarName}: colors.$status_darkOrange;
    }

    &_red {
        #{$strokeColorVarName}: generatedColors.$colorsTetrialRed400;
        #{$bGcolorVarName}: generatedColors.$colorsTetrialRed50;
        #{$textColorVarName}: generatedColors.$colorsTetrialRed500;
    }

    &_blue {
        #{$strokeColorVarName}: colors.$status_blue;
        #{$bGcolorVarName}: generatedColors.$colorsSecondaryLightPurple50;
        #{$textColorVarName}: colors.$status_blue;
    }

    &_gray {
        #{$strokeColorVarName}: generatedColors.$colorsSecondaryGrey100;
        #{$bGcolorVarName}: generatedColors.$colorsSecondaryPurple25;
        #{$textColorVarName}: generatedColors.$colorsSecondaryGrey600;
    }
}
