@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";

.noItemsPlaceholder {
    padding: 22px 16px;
}

.searchInput {
    border-bottom: solid 1px generatedColors.$colorsSecondaryGrey100;
}
