/**
 * DEPRECATED COLORS,
 * PLEASE USE THE NEW ONES FROM tokens/generatedColors.scss
 */

$background_green: #f7fcfa;
$background_secondaryWhite_pressedHovered: rgb(25 28 50 / 40%);
$primary_darkPurple: #4747cc;
$primary_pressedPurple: #001f9a;
$stroke_green: #50c7b2;
$stroke_orange: #f2b16d;
$stroke_blue: #a0a0e5;
$status_green: #00ae8f;
$status_redPressed: #00ae8f;
$status_green: #00ae8f;
$status_darkGreen: #00876f;
$status_darkOrange: #cc8438;
$status_purple: #4747cc;
$status_blue: #41abd9;
$status_darkBlue: #2b92bf;
$table_level3: #ebebff;
$table_hover: #dbdbff;
