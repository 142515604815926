@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";

div.table_variant_5 {
    .ag-root-wrapper {
        border-radius: 4px;
        border-color: #e8e8fc;
    }

    .ag-header {
        border-bottom-color: #e8e8fc;
        background: generatedColors.$colorsSecondaryPurple25;
    }

    .ag-header-cell-label {
        color: generatedColors.$colorsSecondaryGrey600;
        font-family: sans-serif, Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
    }

    .ag-row {
        border-bottom: none;
    }

    div.ag-row-group {
        background: generatedColors.$colorsSecondaryLightPurple100;
    }

    div.ag-row-hover {
        background: #dbdbff;
    }

    .ag-cell {
        color: #000;
        font-family: sans-serif, Inter;
        font-size: 14px;
        font-weight: 400;
    }

    .ag-cell-focus {
        border-color: rgb(255 0 0 / 0%) !important;
        background: generatedColors.$colorsPrimaryPurple;
        color: generatedColors.$colorsPrimaryWhite;
    }

    .ag-cell-last-left-pinned {
        border-color: rgb(0 255 0 / 0%) !important;
        background: none;
        color: #000;
    }

    .ag-row-group .ag-cell-focus {
        border-color: rgb(0 255 0 / 0%) !important;
        background: none;
        color: #000;
    }

    .ag-pinned-left-header,
    .ag-cell-last-left-pinned {
        border-right: none !important;
    }
}
