@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/tokens/generatedColors";

.conditionalRuleBuilder {
    height: calc(100vh - 150px);
    max-height: 625px;

    .mainBuilderSection {
        display: grid;
        grid-gap: 8px;
        grid-template-columns: 225px 225px 225px 225px;
        justify-content: start;
        height: calc(100vh - 150px);
        max-height: 613px;

        .builderColumn {
            position: relative;
            height: calc(100vh - 150px);
            max-height: 613px;
            border: 1px solid generatedColors.$colorsSecondaryGrey100;
            border-radius: radius.$m;
            font-size: 30px;
            text-align: left;

            .scrollableArea {
                height: calc(100vh - 310px);
                max-height: 560px;
                overflow-y: auto;
            }

            .title {
                display: flex;
                justify-content: center;
                gap: 16px;
                padding: 13px 15px;
                border-bottom: 1px solid generatedColors.$colorsSecondaryGrey100;
                background: generatedColors.$colorsSecondaryPurple25;
            }

            .ruleBox {
                display: flex;
                flex-direction: column;
                height: 200px;
                margin: 10px 8px;
                border-radius: radius.$m;
                gap: 10px;
            }
        }

        .formattingBox {
            display: flex;
            flex-direction: column;
            height: 400px;
            margin: 10px 8px;
            border-radius: radius.$m;
            gap: 10px;
        }

        .columnName {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-right: 8px;
            margin-left: 8px;
            padding-top: 15px;
            font-size: 14px;
        }

        .fontStyle {
            display: flex;
            justify-content: center;
            gap: 5px;

            .fontStyleBtn {
                justify-content: center;
                width: 30px;
            }
        }
    }
}

.conditionOperator {
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;

    [role="button"] {
        display: flex;
    }
}

.ruleItem {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 32px);
    margin: 10px 8px;
    padding: 6px 5px 5px 10px;
    border: 1px solid generatedColors.$colorsSecondaryGrey100;
    border-radius: radius.$m;
    background-color: generatedColors.$colorsPrimaryWhite;
    color: generatedColors.$colorsSecondaryGrey100;
    cursor: pointer;
}

.ruleItemSelected {
    border: 1px solid generatedColors.$colorsPrimaryPurple !important;
    background-color: generatedColors.$colorsSecondaryPurple50;
    color: generatedColors.$colorsPrimaryPurple !important;
}

.addBtn {
    display: flex;
    justify-content: center;
    width: 100%;
}

.bottomRightSave {
    position: absolute;
    right: 10px;
    bottom: 10px;
}
