@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/tokens/generatedColors";

.agGridHeaderWithHint {
    display: flex;
    align-items: center;
    column-gap: 6px;
}
