@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/tokens/generatedColors";
@import "src/styles/texts";

.text {
    font-family: $fontFamily;
}

.block {
    display: block;
}

.whiteSpace {
    &_normal {
        white-space: normal;
    }

    &_nowrap {
        white-space: nowrap;
    }

    &_pre {
        white-space: pre;
    }

    &_preWrap {
        white-space: pre-wrap;
    }

    &_preLine {
        white-space: pre-line;
    }
}

.overflow {
    &_hidden {
        overflow: hidden;
    }
}

.textOverflow {
    &_ellipsis {
        text-overflow: ellipsis;
    }
}

.maxLines {
    display: -webkit-box; /* stylelint-disable-line */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: left;
    white-space: normal;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.alignRight {
    text-align: right;
}

.alignCenter {
    text-align: center;
}
