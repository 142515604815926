@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";

.intelas-multiselect-input {
    .current-value {
        display: flex;
        align-items: center;
        min-width: 202px;
        height: 30px;
        padding-right: 6px;
        padding-left: 6px;
        border: 1px solid generatedColors.$colorsSecondaryGrey100;
        border-radius: 4px;
        color: generatedColors.$colorsPrimaryGrey;
        font-family: sans-serif, Inter;
        cursor: pointer;

        .icon {
            width: 32px;
            padding: 6px;

            svg {
                fill: generatedColors.$colorsPrimaryGrey;
            }
        }

        .value_container {
            overflow: hidden;
            white-space: nowrap;

            // width: 140px;
            div {
                width: 500px;
            }
        }

        .sep {
            flex: auto;
        }

        input {
            height: 32px;
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 32px;
            border: none;
        }
    }
}

.multiselect-input-choices {
    width: 400px;
    min-width: 250px;
    max-width: 750px;
    font-family: sans-serif, Inter;

    .input-pane {
        height: 40px;

        .icon {
            position: absolute;
            margin-left: 4px;
            padding: 10px;

            svg {
                fill: generatedColors.$colorsSecondaryGrey600;
            }
        }

        input {
            box-sizing: border-box;
            width: 100%;
            height: 40px;
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 42px;
            border: none;
            outline: none;
            color: generatedColors.$colorsPrimaryGrey;
            font-size: 14px;

            &::placeholder {
                color: generatedColors.$colorsSecondaryGrey600;
            }
        }
    }

    .buttons-pane {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 40px;
        background: generatedColors.$colorsSecondaryPurple25;

        .btn {
            width: 33.3%;
            color: generatedColors.$colorsPrimaryPurple;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            cursor: pointer;

            &:nth-child(1),
            &:nth-child(3) {
                width: 37%;
            }

            &:nth-child(2) {
                width: 26%;
                border-right: 1px solid generatedColors.$colorsSecondaryGrey100;
                border-left: 1px solid generatedColors.$colorsSecondaryGrey100;
            }
        }
    }

    .choices-pane {
        // padding: 8px 16px 8px 16px;
        .choice label {
            // width: 100%;
            display: flex;
            margin: 0;
            padding: 2px 16px 4px 2px;
            cursor: pointer;
        }

        .choice label:hover {
            background-color: generatedColors.$colorsSecondaryPurple25;
        }

        .no-items {
            margin: 10px 0;
            font-size: 14px;
            text-align: center;
        }
    }
}
