@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";

.text {
    flex-grow: 1;
    cursor: pointer;
}

.cell {
    display: flex;
    align-items: center;
}
