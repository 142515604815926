@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";

div.table_variant_1 {
    .ag-root-wrapper {
        border-radius: 4px;
        border-color: #e8e8fc;
    }

    .ag-header {
        border-bottom-color: #e8e8fc;
        background: generatedColors.$colorsSecondaryPurple25;
    }

    .ag-header-cell-label {
        color: generatedColors.$colorsSecondaryGrey600;
        font-family: sans-serif, Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
    }

    //.ag-row {
    //  padding-top: 4px;
    //}

    div.ag-row-group {
        background: generatedColors.$colorsSecondaryLightPurple100;
    }

    div.ag-row-hover {
        background: none;
    }

    .ag-cell {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #000;
        font-family: sans-serif, Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: unset !important;
    }

    .ag-cell-focus {
        border-color: rgb(0 0 0 / 0%) !important;
    }

    *::-webkit-scrollbar:horizontal {
        width: 12px;
        background-color: white;
    }

    *::-webkit-scrollbar-thumb:horizontal {
        border: 3px solid generatedColors.$colorsPrimaryWhite;
        border-radius: 15px;
        background-color: white;
    }
}
