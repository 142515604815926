@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/tokens/generatedColors";

.companyProfileDetails {
    display: flex;
    column-gap: 23px;
}

.table {
    flex-grow: 1;
}
