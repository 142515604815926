@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";

.alert {
    width: 100%;
}

.buttons {
    width: 100%;
}
