@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/animation";
@use "src/styles/tokens/generatedColors";
@use "src/styles/menuItem";

.actions {
    opacity: 0;
}

.item {
    position: relative;
    box-sizing: border-box;
    height: 32px;
    border-radius: radius.$m;
    cursor: pointer;

    &:hover {
        background-color: #{generatedColors.$colorsSecondaryPurple50};
    }

    &_active {
        box-shadow: 0 0 0 1px #{generatedColors.$colorsSecondaryGrey200},
        0 4px 8px 0 rgb(0 0 0 / 10%);

        .itemContent {
            position: relative;
        }
    }

    &:hover {
        .actions {
            opacity: 1;
        }
    }

    &_firstLevel {
        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            top: 9px;
            bottom: 9px;
            left: 6px;
            width: 2px;
            border-radius: 4px;
            background: generatedColors.$colorsPrimaryPurple;
        }
    }
}

.insideActiveFileLevel {
    box-sizing: border-box;
    padding-left: 16px;

    &:not(.item_active) {
        .itemContent {
            position: relative;
        }
    }
}

.levelIndents {
    display: flex;
    align-items: center;
    height: 100%;
}

.levelIndent {
    position: relative;
    width: 24px;
    height: 32px;

    &_active {
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            width: 0;
            border-left: solid 1px generatedColors.$colorsSecondaryLightPurple400;
            border-left-style: dashed;
        }
    }

    &_focused {
        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            top: 9px;
            bottom: 9px;
            left: 50%;
            width: 2px;
            border-radius: 4px;
            background: generatedColors.$colorsPrimaryPurple;
        }
    }
}

.iconPlaceholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
}

