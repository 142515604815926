@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/animation";
@use "src/styles/tokens/generatedColors";

.treeLevelIndent {
    position: relative;
    width: 24px;
    height: 32px;

    // Active indicator with vertical line
    &_active {
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            width: 0;
            border-left: solid 1px generatedColors.$colorsSecondaryLightPurple400;
            border-left-style: dashed;
        }
    }

    &_focused {
        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            top: 9px;
            bottom: 9px;
            left: 50%;
            width: 2px;
            border-radius: 4px;
            background: generatedColors.$colorsPrimaryPurple;
        }
    }

    &_firstLevel {
        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            top: 9px;
            bottom: 9px;
            left: 4px;
            width: 2px;
            border-radius: 4px;
            background: generatedColors.$colorsPrimaryPurple;
        }
    }
}
