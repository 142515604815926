@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/tokens/generatedColors";

.inProgress {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: generatedColors.$colorsPrimaryPurple;
}

.container {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 24px;
    border: 1px dashed generatedColors.$colorsSecondaryPurple300;
    border-radius: radius.$m;
    gap: 24px;
}

.alert {
    width: 100%;
}

.isValidating {
    @extend .inProgress;

    border-style: solid;
    gap: 0;
    background-color: generatedColors.$colorsSecondaryPurple25;
}

.isChecking {
    @extend .inProgress;
}

.logs {
    overflow: auto;
}
