@use "src/styles/tokens/generatedColors";
@use "./radius";
@use "./shadows";
@use "./animation";
@use "./zIndex";

.popover {
    z-index: zIndex.$popover;
    overflow: hidden auto;
    border: solid 1px generatedColors.$colorsSecondaryGrey100;
    border-radius: radius.$m;
    outline: none;
    background-color: generatedColors.$colorsPrimaryWhite;
    box-shadow: shadows.$dropdownElevation;
}
