@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/spaces";

.header {
    display: flex;
    align-items: center;
    height: spaces.$header_height;
    padding-left: 20px;
    background-color: generatedColors.$colorsSecondaryPurple900;
}

.logo_full {
    flex-shrink: 0;
}
