@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";
@use "src/styles/radius";
@use "src/styles/spaces";
@use "src/styles/animation";

.companiesMenu {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    padding: 6px 12px;
    border-bottom: solid 1px #{generatedColors.$colorsSecondaryGrey100};

    &_active {
        background-color: generatedColors.$colorsSecondaryPurple50;
    }
}

.pointer {
    cursor: pointer;
}

.arrow {
    margin-left: 12px;
    transform: rotate(0deg);
    transition: transform animation.$speed_normal animation.$pattern_default;

    &_rotated {
        transform: rotate(180deg);
    }
}

.avatarPlaceholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: radius.$l;
    background: generatedColors.$colorsSecondaryLightPurple100;
}

.nameBox {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 12px;
}
