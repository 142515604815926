@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";

.header {
    box-sizing: border-box;
    align-items: center;
    min-width: 320px;
    max-width: 100%;
    border-bottom: 1px solid generatedColors.$colorsSecondaryGrey100;
    background-color: generatedColors.$colorsSecondaryPurple25;
}
