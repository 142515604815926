@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/animation";
@use "src/styles/menuItem";
@use "src/styles/tokens/generatedColors";

.childrenWrapper {
    display: none;

    .itemText {
        padding-left: 36px;
    }

    &_visible {
        display: block;
    }
}

.groupArrow {
    transform: rotate(0deg);
    transition: transform animation.$speed_normal animation.$pattern_default;

    &_active {
        transform: rotate(180deg);
    }
}

.itemIcon {
    margin-right: 12px;
}

.item {
    display: flex;
    position: relative;
    flex-grow: 1;
    align-items: center;
    padding: 12px 12px 12px 20px;
    cursor: pointer;

    @include menuItem.menuItemStates;
}
