@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";

.dialogPaper {
    width: 100%;
    max-width: 500px !important;
}

.objectForm {
    padding: 24px;
}

.title {
    margin: 0 0 24px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
}

.loadingOverlay {
    display: flex;
    position: absolute;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: calc(100% - 48px);
    height: calc(100% - 48px);
    background: rgb(255 255 255 / 70%);
}

.warningText {
    margin-bottom: 24px;
    color: generatedColors.$colorsSecondaryGrey600;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 16px;
}
