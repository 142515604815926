@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/animation";
@use "src/styles/tokens/generatedColors";
@import "src/styles/texts";

$verticalPaddingVarName: --intelas-text-input-vertical-padding;
$borderColorVarName: --intelas-text-input-border-color;
$boxShadowColorVarName: --intelas-text-input-box-shadow-color;

.textInput {
    @extend .text_variant_smallTextMediumDefault;

    box-sizing: border-box;
    width: 100%;
    font-family: $fontFamily;
}

.container {
    @include shadows.boxShadowOuter(var($boxShadowColorVarName, transparent));

    display: flex;
    flex-grow: 1;
    max-width: 100%;
    padding: var($verticalPaddingVarName) 11px;
    overflow: hidden;
    column-gap: 12px;
    border: none;
    background-color: generatedColors.$colorsPrimaryWhite;

    &_iconPos_end {
        flex-direction: row-reverse;
    }
}

.input {
    @extend .text_variant_smallTextMediumDefault;

    flex-grow: 1;
    max-width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    border: 0;
    outline: 0;
    background-color: transparent;
    color: generatedColors.$colorsPrimaryGrey;
    font-family: $fontFamily;

    &::placeholder {
        color: generatedColors.$colorsSecondaryGrey600;
    }

    &:focus {
        outline: 0;
    }
}

.icon {
    transition: transform animation.$speed_normal animation.$pattern_default;
}

.iconRotate {
    transform: rotate(180deg);
}

.colorSquare {
    width: 20px;
    height: 20px;
    border-radius: 5px;
}
