@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";

div.table_variant_3 {
    .ag-header {
        border-radius: 4px;
        border-bottom-color: #e8e8fc;
        background: generatedColors.$colorsSecondaryPurple25;
    }

    .ag-header-cell-label {
        color: generatedColors.$colorsSecondaryGrey600;
        font-family: sans-serif, Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
    }

    .ag-row {
        border-bottom-color: #e8e8fc;
    }

    .ag-cell {
        color: #000;
        font-family: sans-serif, Inter;
        font-size: 16px;
        font-weight: 400;
    }

    .ag-cell-focus {
        border-color: rgb(0 0 0 / 0%) !important;
    }

    // ag grid 3

    .ag-root-wrapper {
        border: none;
        background: none;
    }

    .ag-header {
        border-bottom: none;
    }

    .ag-header-row-column-group {
        border-bottom: 1px solid generatedColors.$colorsPrimaryWhite;
        background: #e8e8fc;

        .ag-header-group-cell-label {
            justify-content: center;
            font-family: sans-serif, Inter;
            font-weight: 700;
        }

        .ag-header-group-cell .ag-header-cell-resize::after {
            top: 0;
            width: 1px;
            height: 33px;
            background-color: generatedColors.$colorsPrimaryWhite;
        }
    }

    .ag-header {
        margin-bottom: 4px;
    }

    .ag-header-cell-label {
        color: generatedColors.$colorsSecondaryGrey600;
        font-weight: 700;
    }

    .ag-row {

        // REFACTOR: Stop using this limitation. We can't do adaptive height with than.
        max-height: 34px;
        border: 1px solid #e8e8fc;
        border-radius: 4px;
    }

    .ag-cell {
        margin-top: -3px;
        font-size: 12px;
    }

    .ag-row-hover {
        background: colors.$table_hover;
    }

    //.ag-cell:nth-child(3), .ag-cell:nth-child(5) {
    //    border-right: 1px solid #E8E8FC;
    //}

    .ag-body-horizontal-scroll {
        display: none;
    }

    *::-webkit-scrollbar:horizontal {
        width: 12px;
        background-color: white;
    }

    *::-webkit-scrollbar-thumb:horizontal {
        border: 3px solid generatedColors.$colorsPrimaryWhite;
        border-radius: 15px;
        background-color: white;
    }
}
