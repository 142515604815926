@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";
@use "src/styles/tooltip";

.counter {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    height: 26px;
    padding: 4px 12px;
    border-radius: 28px;
    background-color: generatedColors.$colorsSecondaryPurple50;
    cursor: default;

    &:hover {
        background-color: generatedColors.$colorsSecondaryPurple200;
    }
}

.container {
    padding-right: 4px;
    padding-left: 4px;
    overflow-y: auto;
}

.container::-webkit-scrollbar-thumb {
    border-color: tooltip.$backgroundColor;
    background: generatedColors.$colorsSecondaryGrey100;
}

.container::-webkit-scrollbar-track {
    background: tooltip.$backgroundColor;
}
