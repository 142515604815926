@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";
@import "src/styles/texts";

.textarea {
    @extend .text_variant_smallTextMediumDefault;

    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    color: generatedColors.$colorsPrimaryGrey;
    font-family: $fontFamily;
    resize: none;

    &::placeholder {
        color: generatedColors.$colorsSecondaryGrey600;
    }
}
