@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";
@use "src/styles/radius";
@use "src/styles/shadows";

$borderColorVarName: --intelas-text-input-border-color;

.wrapper {
    row-gap: 4px;
    display: flex;
    flex-direction: column;
}

.content {
    display: flex;
    box-sizing: border-box;
    flex-shrink: 0;
    align-items: center;
    border-width: 0;
    border-style: solid;
    border-radius: radius.$m;
    background-color: generatedColors.$colorsPrimaryWhite;
    user-select: none;

    &:not(.labelsOnly, .borderless) {
        border-width: 1px;
        border-color: var($borderColorVarName, generatedColors.$colorsSecondaryGrey100);

        &:not(.disabled) {
            &.active {
                border-color: generatedColors.$colorsPrimaryPurple;
            }

            &:focus-within {
                @include shadows.boxShadowOuter(generatedColors.$buttonsPrimaryPBorderFocused);

                border-color: generatedColors.$colorsPrimaryPurple;
            }

            &:hover {
                border-color: generatedColors.$colorsPrimaryPurple;
            }
        }
    }
}

.size {
    &_s:not(.labelsOnly) {
        padding: 5px 12px;
    }

    &_l:not(.labelsOnly) {
        padding: 9px 12px;
    }
}

.label {
    margin-right: 6px;
}

.hasError {
    #{$borderColorVarName}: generatedColors.$colorsTetrialRed400;
}

.hint {
    margin-top: 4px;
}

.isDisabled {
    opacity: 0.4;
}

.borderless {
    border-color: transparent;
}
