@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/tooltip";
@use "src/styles/tokens/generatedColors";
@import "src/styles/texts";

.container {
    border: 1px solid generatedColors.$colorsSecondaryGrey100;
    border-radius: radius.$m;
    background-color: generatedColors.$colorsPrimaryWhite;
}

.title {
    padding: 8px 12px;
    border-bottom: 1px solid generatedColors.$colorsSecondaryGrey100;
}

.chart {
    @extend .text_variant_labelMediumDefault;

    padding: 16px;
    font-family: $fontFamily;
    font-size: 11px; // overriding fontsize to fit large numbers into axis fields
}
