@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/animation";
@use "src/styles/tokens/generatedColors";
@import "src/styles/texts";

.wrapper {
    flex-grow: 1;
}

.uploadArea {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    padding: 32px;
    border: 1px dashed generatedColors.$colorsSecondaryPurple300;
    border-radius: 8px;
    gap: 32px;
    background-color: generatedColors.$colorsPrimaryWhite;
    font-family: $fontFamily;
}

.verticalLayout {
    flex-direction: column;
    height: 100%;
    min-height: 340px;
    gap: 16px;
}

.hasError {
    border-color: generatedColors.$colorsTetrialRed500;
}

.inProgress {
    flex-direction: column;
    border-style: solid;
    background-color: generatedColors.$colorsSecondaryPurple25;
    gap: 16px;
}

.fileOver:not(.inProgress) {
    background-color: generatedColors.$colorsSecondaryPurple25;
}

.uploadImage {
    display: block;
    width: 128px;
    height: 128px;
    object-fit: contain;
}

.uploadMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 290px;
    padding-top: 4px;
}

.uploadMessageText {
    margin-bottom: 16px;
}

.supportedFilesHint {
    cursor: default;
}

.progressMessage {
    max-width: 256px;
    text-align: center;
}

.input {
    display: none;
}
