@use "src/styles/tokens/generatedColors";

// Util for menu items
// $offset is used to correct the height of the active state pseudo element
@mixin menuItemStates($offset: 8px) {
    &:hover {
        background-color: generatedColors.$colorsSecondaryPurple50;
    }

    &_active {
        background-color: generatedColors.$colorsSecondaryPurple50;

        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            top: $offset;
            bottom: $offset;
            left: 0;
            width: 4px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            background: generatedColors.$colorsPrimaryPurple;
        }
    }
}
