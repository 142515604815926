@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";
@use "src/styles/radius";
@use "src/styles/animation";
@use "src/styles/variableNames";
@import "src/styles/texts";

.table :global {
    --ag-odd-row-background-color: transparent;
    --ag-border-color: #{generatedColors.$colorsSecondaryGrey100};
    --ag-borders: solid 1px;
    --ag-border-radius: #{radius.$m};
    --ag-header-foreground-color: #{generatedColors.$colorsSecondaryGrey600}; // Header font color
    --ag-header-background-color: #{generatedColors.$colorsSecondaryPurple25};
    --ag-cell-horizontal-padding: 12px;
    --ag-alpine-active-color: transparent;
    --ag-row-hover-color: #{generatedColors.$colorsSecondaryLightPurple100};

    // Resize element
    --ag-header-column-resize-handle-height: 100%;
    --ag-header-column-resize-handle-color: transparent;
    --ag-header-column-resize-handle-width: 20px;

    #{variableNames.$agCellBorder}: generatedColors.$colorsSecondaryGrey100;
}

.table {
    :global(.ag-center-aligned-cell) {
        text-align: center;
    }

    // TODO: remove hardcode border classes
    :global(.ag-header-viewport .ag-header-group-cell.rightBorder1),
    :global(.ag-header-row-column .ag-right-aligned-header.rightBorder1),
    :global(.ag-header-row-column .ag-right-aligned-cell.rightBorder1) {
        border-right: var(--ag-borders) var(--ag-border-color);
    }

    :global(.ag-header-row .noTopBorder) {
        border-top: none;
    }

    :global(.ag-header-row-column .ag-right-aligned-header.rightBorder4),
    :global(.ag-header-row-column .ag-right-aligned-cell.rightBorder4) {
        border-right: solid 4px var(--ag-border-color);
    }

    :global(.ag-center-cols-container .ag-right-aligned-header.rightBorder1),
    :global(.ag-center-cols-container .ag-right-aligned-cell.rightBorder1) {
        border: none;
        border-right: var(--ag-borders) var(--ag-border-color);
    }

    :global(.ag-center-cols-container .ag-right-aligned-header.rightBorder4),
    :global(.ag-center-cols-container .ag-right-aligned-cell.rightBorder4) {
        border: none;
        border-right: solid 4px var(--ag-border-color);
    }

    :global(.ag-header-cell-text) {
        font-family: $fontFamily;

        @extend .text_variant_labelMediumDefault;
    }

    :global(.ag-cell-value) {
        @extend .text_variant_cell_smallTextMediumDefault;

        color: generatedColors.$colorsPrimaryGrey;
        font-family: $fontFamily;
    }

    // category header row
    :global(.ag-center-cols-container .ag-cell-value) {
        transition: color animation.$speed_fast animation.$pattern_default;
    }

    :global(.ag-center-cols-container .ag-row-group.ag-row-group-expanded .ag-cell-value) {
        color: transparent;
    }

    // category total row
    :global(.ag-center-cols-container .ag-row-footer) {
        border-top: solid 1px generatedColors.$colorsPrimaryGrey;
        border-bottom: solid 1px generatedColors.$colorsSecondaryPurple25;
        background-color: generatedColors.$colorsSecondaryPurple50;
        line-height: 20px;
    }

    // category total row: level-0
    :global(.ag-center-cols-container .ag-row-footer.ag-row-level-0) {
        --ag-row-hover-color: #{generatedColors.$colorsSecondaryLightPurple100};

        background-color: generatedColors.$colorsSecondaryPurple50;
    }

    // calculation row
    :global(.ag-center-cols-container .calculation) {
        --ag-row-hover-color: #{generatedColors.$colorsSecondaryLightPurple900};

        border-top: solid 4px generatedColors.$colorsPrimaryWhite;
        border-bottom: solid 4px generatedColors.$colorsPrimaryWhite;
        background-color: generatedColors.$colorsSecondaryPurple900;
        color: generatedColors.$colorsPrimaryWhite;
    }

    :global(.ag-center-cols-container .calculation .ag-cell-value) {
        color: generatedColors.$colorsPrimaryWhite;
    }

    :global(.calculation .ag-cell-value) {
        @extend .text_variant_cell_smallTextSemiboldDefault;

        line-height: 22px;
    }

    :global(.calculation.ag-row-level-0) {
        --ag-line-height: 22px !important;

        border-top: solid 4px generatedColors.$colorsPrimaryWhite;
        border-bottom: solid 4px generatedColors.$colorsPrimaryWhite;
    }

    :global(.ag-ltr .calculation .ag-row-group-leaf-indent) {
        margin-left: 0;
    }

    :global(.ag-row-footer .ag-cell-value) {
        @extend .text_variant_cell_smallTextSemiboldDefault;
    }

    :global(.ag-row-group .ag-icon:hover) {
        --ag-alpine-active-color: #{generatedColors.$colorsPrimaryPurple};
    }

    :global(.ag-root-wrapper) {
        border-radius: radius.$m;
    }

    // Status bar (pagination)
    :global(.ag-status-bar) {
        padding: 6px 12px;
        background-color: generatedColors.$colorsSecondaryPurple25;
    }

    // Box in status bar
    :global(.ag-react-container) {
        display: flex;
        align-items: center;
    }

    :global(.ag-row-group.ag-row-odd) {
        background-color: var(--ag-background-color);
    }

    // Clickable cell
    :global(.account .clickableCell) {
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}

:global(.ag-header-group-cell-label) {
    justify-content: center;
}

:global(.headerGroupLeftAlinged .ag-header-group-cell-label) {
    justify-content: left;
}

:global {
    .agCell_type_string .ag-cell-wrapper {
        overflow: hidden;
    }
}
