@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/spaces";
@use "src/styles/tokens/generatedColors";

.pageLayout {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.loaderContainer {
    position: relative;
    height: 100%;
}

.content {
    display: flex;
    flex: auto;
    flex-direction: column;
    overflow: auto;
}

.xPadding {
    padding-right: spaces.$mainPage_horizontalPadding;
    padding-left: spaces.$mainPage_horizontalPadding;
}

.yPadding {
    padding-top: spaces.$mainPage_verticalPadding;
    padding-bottom:  spaces.$mainPage_verticalPadding;
}
