@use "src/styles/tokens/generatedColors";

// Use this mixin for focus / hover state of elements
@mixin boxShadowOuter($color: transparent, $width: 2px) {
    box-shadow: 0 0 0 $width $color;
}

// Use this mixin for focus / hover state
// of elements inside a container with borders / overflow: hidden,
// when outer shadow can not be applied
@mixin boxShadowInner($color: transparent, $width: 2px) {
    box-shadow: inset 0 0 0 $width $color;
}

$dropdownElevation: 0 2px 16px rgb(25 28 50 / 16%);
$elevation01: 0 4px 8px rgb(0 0 0 / 12%);
$elevation02: 0 16px 32px rgb(0 0 0 / 20%);
$elevation03: 0px 27px 25.9px 0px generatedColors.$colorsSecondaryPurple25;
