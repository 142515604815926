@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/tokens/generatedColors";

.userNoteComment {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    max-width: 400px;
    padding: 12px;
    border-radius: radius.$m;
    row-gap: 12px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.userLabel {
    display: flex;
    position: relative;
    align-items: center;
    column-gap: 16px;
}
