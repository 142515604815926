@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/animation";
@use "src/styles/tokens/generatedColors";
@use "src/styles/zIndex";
@import "src/styles/texts";

.popup {
    min-width: 300px;
    border-radius: radius.$m;
}

.container {
    position: relative;
    padding: 24px;
}

.title {
    flex-grow: 1;
}

.head {
    display: flex;
    align-items: center;
    margin: 0 0 24px;
}

.closeButton {
    display: flex;
    flex-shrink: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    color: generatedColors.$colorsSecondaryGrey600;

    &:hover,
    &:active {
        color: generatedColors.$colorsPrimaryGrey;
    }
}

.loadingOverlay {
    display: flex;
    position: absolute;
    z-index: zIndex.$baseModal;
    inset: 0;
    background: rgb(255 255 255 / 70%);
}

.text {
    margin-bottom: 24px;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 16px;
}
