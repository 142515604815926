@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";

.modal {
    padding: 20px 20px 0;
}

.header {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

.closeCross {
    cursor: pointer;
}

.tabs {
    margin-top: -25px;
    padding-left: 24px;
}

.footer {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    gap: 10px;
    margin-top: auto;
    margin-left: auto;
}
