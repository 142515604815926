@use "src/styles/tokens/generatedColors";
@use "src/styles/radius";
@use "src/styles/animation";
@use "src/styles/variableNames";
@use "src/styles/texts";

.table :global {
    // font
    --ag-font-size: 13px;
    --ag-font-family: #{texts.$fontFamily};
    // sizes
    --ag-cell-horizontal-padding: 16px;
    --ag-row-height: 38px;
    --ag-header-height: 30px;
    --ag-header-column-resize-handle-width: 1px;
    --ag-header-column-resize-handle-height: 24px;
    // borders
    --ag-borders: solid 1px;
    --ag-row-border-style: solid;
    --ag-border-radius: #{radius.$l};
    // colors
    --ag-row-border-color: #{generatedColors.$colorsSecondaryGrey100};
    --ag-border-color: #{generatedColors.$colorsSecondaryGrey100};
    --ag-odd-row-background-color: transparent;
    --ag-header-background-color: #{generatedColors.$colorsSecondaryPurple25};
    --ag-header-foreground-color: #{generatedColors.$colorsSecondaryGrey600};
    --ag-data-color: #{generatedColors.$colorsPrimaryGrey};
    --ag-header-column-resize-handle-color: #{generatedColors.$colorsTetrialBlue100};
    --ag-row-hover-color: #{generatedColors.$colorsSecondaryLightPurple100};
    --ag-alpine-active-color: #{generatedColors.$colorsTetrialBlue500};
    --ag-active-color: #{generatedColors.$colorsTetrialBlue500};
    // icons
    --ag-icon-font-family: "agGridMaterial";
    --ag-icon-size: 14px;
}

.table {
    font-weight: 500;

    // headers
    :global(.ag-header-row) {
        font-size: 12px;
        font-weight: 500;
    }

    :global(.ag-header-row .header-l1) {
        --ag-header-column-resize-handle-color: transparent;

        background-color: #8895b5 !important; // not in our tokens, not even close to any!
        color: generatedColors.$colorsPrimaryWhite;
        font-weight: 600;
    }

    // border classes
    :global(.right-border-1) {
        border-right: var(--ag-borders) var(--ag-border-color);
    }

    // clickable rows
    :global(.clickable-row) {
        cursor: pointer;
    }

    // footer
    :global(.footer-row) {
        background-color: generatedColors.$colorsTetrialBlue100;
        color: generatedColors.$colorsTetrialBlue600;
        font-weight: 600;
    }
}
