@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";

.splitter {
    display: block;
    width: 100%;
    height: 1px;
    margin-top: 16px;
    background: generatedColors.$colorsSecondaryGrey100;
}

.chart {
    display: block;
    width: 100%;
}

.tableContainer {
    width: 100%;
    height: 380px;
}
