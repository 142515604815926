@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/tokens/generatedColors";

.agGridTablePaginationRightPanel {
    display: flex;
    align-items: center;
    gap: 20px;
}

// FIXME (ALEKSEY): Remove this and use Button props. In discussion with Marta.
.arrowButton {
    width: 24px;
    height: 24px;
    padding: 1px;
}

.buttonsBox {
    display: flex;
    align-items: center;
    gap: 12px;
}
