@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/tokens/generatedColors";

.treeItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0 10px 8px;
    background: generatedColors.$colorsSecondaryLightPurple100;
    color: generatedColors.$colorsPrimaryGrey;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
}

.sep {
    flex: auto;
}

.icon {
    margin-right: 8px;

    svg {
        fill: generatedColors.$colorsSecondaryGrey400;
    }
}

.tree {
    &:hover {
        background: #dbdbff;

        &.accountItemSelected {
            background: #dbdbff;
        }
    }
}

.menuIcon {
    width: 24px;
    height: 22px;
    margin-right: 12px;
    cursor: pointer;

    &:hover path {
        fill: generatedColors.$colorsPrimaryPurple;
    }
}

.opened {
    color: #4747cc;
}

.topLevel {
    padding-left: 12px;
    border-bottom: 1px solid generatedColors.$colorsSecondaryGrey100;
    background: generatedColors.$colorsSecondaryPurple25;
    color: generatedColors.$colorsSecondaryGrey600;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    cursor: default;

    &:hover {
        background: generatedColors.$colorsSecondaryPurple25;
    }
}

.text {
    &_last {
        // FIXME: Magic number: (Icon width) + (Icon padding)
        margin-left: 28px;
    }
}
