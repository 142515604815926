@use "src/styles/radius";
@use "src/styles/tokens/generatedColors";
@use "src/styles/colors";

.alert {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    padding: 16px;
    border: 1px solid colors.$stroke_orange;
    border-radius: radius.$m;
    background: generatedColors.$colorsTetrialRed50;

    a {
        color: colors.$primary_darkPurple;
    }
}

.message {
    margin-bottom: 8px;

    div {
        margin-bottom: 4px;
    }
}

.accounts {
    margin-bottom: 4px;
}
