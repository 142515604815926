@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";
@use "src/styles/radius";
@use "src/styles/shadows";

.deleteButton {
    justify-content: center;
    width: 100%;
    margin-top: 20px;
}
