@use "@/styles/colors";
@use "@/styles/radius";
@use "src/styles/tokens/generatedColors";

.pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.prevNext {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 16px;
}

.pageNumber {
    margin: 0 16px;
}

.arrowButton {
    width: 24px;
    height: 24px;
    padding: 1px;
}
