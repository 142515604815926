@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";

.modal {
    padding: 40px 36px 24px;
}

.header {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

.content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    overflow: auto;
}

.uploadBlock {
    height: 341px;
}

.footer {
    display: flex;
    flex-shrink: 0;
    align-items: center;
}

.footerText {
    margin-right: 16px;
    margin-left: auto;
}
