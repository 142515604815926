@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/spaces";
@use "src/styles/tokens/generatedColors";

.navigationHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: spaces.$datePicker_mainPadding;
    border: none;
    background: transparent;
}

.selectsBox {
    display: flex;
    column-gap: 16px;
}
