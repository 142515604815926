@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/spaces";
@use "src/styles/zIndex";
@use "src/styles/tokens/generatedColors";
@import "src/styles/texts";

$daySize: 40px;
$dayDotSize: 4px;

@mixin day {
    display: inline-flex !important;
    position: relative;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    width: $daySize;
    height: $daySize;
    border-width: 1px;
    border-style: solid;
    border-radius: radius.$m;
    border-color: transparent;
    outline: none;
    color: generatedColors.$colorsPrimaryGrey;
    font-family: $fontFamily;
    user-select: none;

    &:hover {
        background-color: generatedColors.$colorsSecondaryGrey50;
    }
}

.calendarClassName {
    padding: 0;
    border: solid 1px generatedColors.$colorsSecondaryGrey100 !important;
    border-radius: radius.$m;
    background-color: generatedColors.$colorsPrimaryWhite;
    box-shadow: shadows.$dropdownElevation;
}

.dayName {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    width: $daySize;
    height: $daySize;
    color: generatedColors.$colorsSecondaryGrey600;
    font-family: $fontFamily;
    user-select: none;

    @extend .text_variant_smallTextMediumDefault;
}

.day {
    @include day;
    @extend .text_variant_smallTextMediumDefault;
}

// Wrap everything with local class to save scope
.popper {
    z-index: zIndex.$datePickerPopover;
    // Styles which is can't be changed with props
    :global {
        .react-datepicker__day-names {
            display: flex;
            padding: 0 spaces.$datePicker_mainPadding;
            border-top: solid 1px generatedColors.$colorsSecondaryGrey100;
            border-bottom: solid 1px generatedColors.$colorsSecondaryGrey100;
            background: generatedColors.$colorsPrimaryWhite;
        }

        .react-datepicker__header {
            padding: 0;
            border: none;
            background-color: transparent;
        }

        .react-datepicker__day--today,
        .react-datepicker__month-text--today,
        .react-datepicker__year-text--today {
            color: generatedColors.$colorsPrimaryPurple;

            &::after {
                content: "";
                position: absolute;
                top: calc(#{$daySize} - 10px);
                left: calc(50% - #{$dayDotSize} / 2);
                width: $dayDotSize;
                height: $dayDotSize;
                border-radius: 50%;
                background-color: generatedColors.$colorsPrimaryPurple;
            }
        }

        .react-datepicker__quarter-text {
            @include day;

            font-size: 14px;
            font-weight: 500;
        }

        .react-datepicker__year-text {
            @include day;

            width: 4rem;
            font-size: 14px;
            font-weight: 500;
        }

        .react-datepicker__day--selected,
        .react-datepicker__month--selected,
        .react-datepicker__quarter--selected,
        .react-datepicker__year-text--selected {
            border-color: generatedColors.$colorsPrimaryPurple;
            background-color: generatedColors.$colorsSecondaryLightPurple200;
            color: generatedColors.$colorsPrimaryPurple;
        }

        .react-datepicker__month,
        .react-datepicker__year {
            margin: 0;
            padding: 8px spaces.$datePicker_mainPadding;
            border-top: solid 1px generatedColors.$colorsSecondaryGrey100;
        }

        .react-datepicker__day--keyboard-selected,
        .react-datepicker__month-text--keyboard-selected,
        .react-datepicker__quarter-text--keyboard-selected,
        .react-datepicker__year-text--keyboard-selected {
            background-color: generatedColors.$colorsSecondaryLightPurple200;
        }

        .react-datepicker__day--outside-month {
            color: generatedColors.$colorsSecondaryGrey400;
        }

        .react-datepicker__day--disabled,
        .react-datepicker__month--disabled,
        .react-datepicker__year-text--disabled {
            color: generatedColors.$colorsSecondaryGrey50;

            &:hover {
                background-color: transparent;
            }
        }

        .react-datepicker__month-text:hover,
        .react-datepicker__quarter-text:hover {
            background-color: generatedColors.$colorsSecondaryLightPurple200;
        }

        .react-datepicker__year-wrapper {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            max-width: fit-content;
        }

        .react-datepicker-year-header {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 64px;
            color: generatedColors.$colorsPrimaryGrey;
            font-family: $fontFamily;
            font-size: 14px;
            font-weight: 500;
            user-select: none;
        }

        .react-datepicker__navigation-icon::before {
            content: "";
            display: block;
            position: absolute;
            top: 6px;
            width: 24px;
            height: 24px;
            border: 0;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.3623 17C13.1003 17 12.8393 16.898 12.6433 16.695L8.7803 12.695C8.4023 12.302 8.4073 11.679 8.7933 11.293L12.7933 7.293C13.1833 6.902 13.8163 6.902 14.2073 7.293C14.5973 7.684 14.5973 8.316 14.2073 8.707L10.9023 12.012L14.0813 15.305C14.4653 15.703 14.4543 16.336 14.0573 16.719C13.8623 16.907 13.6123 17 13.3623 17Z' fill='%235c5ce5'/%3E%3C/svg%3E");
        }

        .react-datepicker__navigation-icon--previous::before {
            top: 0;
            right: -10px;
            transform: none;
        }

        .react-datepicker__navigation-icon--next::before {
            top: 0;
            left: -10px;
            transform: rotate(180deg);
        }

        .react-datepicker__navigation--previous {
            top: 16px;
            left: 12px;
        }

        .react-datepicker__navigation--next {
            top: 16px;
            right: 12px;
        }
    }
}

.wrapper {
    max-width: fit-content;
}
