@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";
@use "src/styles/radius";

.companiesMenuItem {
    padding: 14px 16px !important;

    &_active {
        background-color: generatedColors.$colorsSecondaryPurple50;
    }
}
