@use "src/styles/colors";
@use "src/styles/tokens/generatedColors";
@use "src/styles/radius";
@use "src/styles/spaces";

.formBox {
    overflow: hidden;
    border: solid 1px generatedColors.$colorsSecondaryGrey100;
    border-radius: radius.$l;
}

.header {
    background-color: generatedColors.$colorsSecondaryPurple25;
}

.body,
.header {
    position: relative;
    padding: 16px spaces.$formBox_horizontalPadding;
}

.body {
    min-height: 60px;
}

.noBottom {
    border-bottom: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.noTop {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.bgColor {
    &_colorsSecondaryLightPurple50 {
        background-color: generatedColors.$colorsSecondaryLightPurple50;
    }
}
