@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/tokens/generatedColors";

.titleCell {
    min-width: 150px;
}

.cell {
    padding-top: 8px;
    padding-bottom: 8px;
}
