@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/animation";
@use "src/styles/tokens/generatedColors";

.elementsPerPageSelect {
    display: flex;
    column-gap: 5px;
}

.number {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.arrow {
    transform: rotate(0);
    transition: transform animation.$speed_normal animation.$pattern_default;

    &_open {
        transform: rotate(180deg);
    }
}
