@use "src/styles/utils";

.hidden {
    @extend .visuallyHidden;
}

.chartContainer {
    width: 100%;
    height: 100%;
}
