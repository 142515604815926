@use "src/styles/colors";
@use "src/styles/radius";
@use "src/styles/shadows";
@use "src/styles/animation";
@use "src/styles/tokens/generatedColors";

.inputField {
    box-sizing: border-box;
    flex-grow: 1;
    min-height: 24px;
    padding-left: 6px;
    border: var(--ag-borders-input, 1px solid) var(--ag-input-border-color, generatedColors.$colorsSecondaryGrey400);
    border-radius: var(--ag-border-radius, 4px);
    outline: none;
    background-color: generatedColors.$colorsPrimaryWhite;

    &:hover,
    &:focus,
    &:active {
        &:not(.disabled) {
            border-color: generatedColors.$colorsPrimaryPurple;
        }
    }

    &:focus-within {
        &:not(.disabled) {
            @include shadows.boxShadowOuter(generatedColors.$colorsSecondaryLightPurple200);
        }
    }
}
